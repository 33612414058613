import React from "react"
import isValidArray from "helpers/isValidArray"
import Button from "components/_common/Button"
import SectionHeading from "components/_common/SectionHeading"
import Wrapper from "components/_common/Wrapper"
import withSbEditable from "components/_bloks/withSbEditable"
import TradedMaterialsColumn from "./TradedMaterialsColumn"
import s from "./tradedMaterials.module.scss"

const TradedMaterials = ({
  materials,
  heading,
  cta_name,
  cta_url,
  padding_top,
  padding_top_mb,
  padding_bottom,
  padding_bottom_mb,
}) => {
  if (!isValidArray(materials)) {
    return null
  }

  return (
    <Wrapper
      paddingTop={padding_top}
      paddingTopMb={padding_top_mb}
      paddingBottom={padding_bottom}
      paddingBottomMb={padding_bottom_mb}
      className={s.wrapper}
      fullWidth
    >
      <div className="container">
        <h3 className={s.heading}>{heading}</h3>
        <div className={s.inner}>
          {materials.map(traded_materials_column => (
            <TradedMaterialsColumn
              key={traded_materials_column._uid}
              blok={traded_materials_column}
            />
          ))}
        </div>
        <div className={s.cta}>
          <Button
            className={s.ctaButton}
            fontBig={true}
            text={cta_name}
            link={cta_url?.cached_url}
          />
        </div>
      </div>
    </Wrapper>
  )
}

export default withSbEditable(TradedMaterials)
