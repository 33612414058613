import React from "react"
import withSbEditable from "components/_bloks/withSbEditable"
import Wrapper from "components/_common/Wrapper"
import YouTubeEmbedded from "./components/YouTubeEmbedded"

const CustomEmbedded = ({
  type,
  html,
  youtube_video_id,
  youtube_iframe_title,
  youtube_iframe_allow,
  padding_top,
  padding_top_mb,
  padding_bottom,
  padding_bottom_mb,
}) => {
  const parsedHTML = type === "html" && html && html.replace(/[“”"]/g, "'")

  function renderChildren() {
    switch (type) {
      case "html":
        if (!parsedHTML) return null
        return <div dangerouslySetInnerHTML={{ __html: parsedHTML }} />
      case "youtube":
        return (
          <YouTubeEmbedded
            youtube_video_id={youtube_video_id}
            youtube_iframe_title={youtube_iframe_title}
            youtube_iframe_allow={youtube_iframe_allow}
          />
        )
      default:
        return null
    }
  }

  return (
    <Wrapper
      paddingTop={padding_top}
      paddingTopMb={padding_top_mb}
      paddingBottom={padding_bottom}
      paddingBottomMb={padding_bottom_mb}
    >
      {renderChildren()}
    </Wrapper>
  )
}

export default withSbEditable(CustomEmbedded)
