import React, { useState } from "react"
import ReactPlayer from "react-player/lazy"
import cns from "classnames"
import withSbEditable from "components/_bloks/withSbEditable"
import s from "./videoShowcase.module.scss"

const VideoShowcaseItem = ({ heading, video_url }) => {
  const [isPlaying, setIsPlaying] = useState(false)

  function handleOnReady() {
    setIsPlaying(true)
  }

  return (
    <div className={cns(s.videoItem, isPlaying && s.isPlaying)}>
      <ReactPlayer
        url={video_url}
        onReady={handleOnReady}
        className={s.player}
        width="100%"
        height="100%"
        light
        controls
        playing
      />
      <h4>{heading}</h4>
    </div>
  )
}

export default withSbEditable(VideoShowcaseItem)
