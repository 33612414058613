import { useEffect, useState } from "react"

export default function useSessionStorage(
  key,
  initialValue,
  fallbackValue,
  skip
) {
  if (typeof window === "undefined" || skip) {
    return [fallbackValue, () => {}]
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [value, set] = useState(null)

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    try {
      const sessionStorageValue = sessionStorage.getItem(key)
      if (typeof sessionStorageValue !== "string") {
        sessionStorage.setItem(key, JSON.stringify(initialValue))
        set(initialValue)
      } else {
        set(JSON.parse(sessionStorageValue || "null"))
      }
    } catch {
      set(fallbackValue)
    }
  }, [key, initialValue, fallbackValue])

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    try {
      const serializedState = JSON.stringify(value)
      sessionStorage.setItem(key, serializedState)
    } catch {}
  })

  return [value, set]
}
