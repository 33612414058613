import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import YouTubeIcon from "@material-ui/icons/YouTube"
import cns from "classnames"
import s from "./youTubeEmbedded.module.scss"

function YouTubeEmbedded({
  youtube_video_id,
  youtube_iframe_title,
  youtube_iframe_allow,
}) {
  const intl = useIntl()

  if (!youtube_video_id) return null
  return (
    <>
      <div className={s.container}>
        <div
          className={cns(
            s.containerPlaceholderWrapper,
            "cookieconsent-optout-marketing"
          )}
        >
          <div className={s.containerPlaceholder}>
            <YouTubeIcon className={s.containerPlaceholderIcon} />

            <p className={s.containerPlaceholderText}>
              {intl.formatMessage({
                id: "Please accept marketing cookies to view this content.",
              })}
            </p>
          </div>
        </div>
        <iframe
          data-cookieconsent="marketing"
          data-cookieblock-src={`https://www.youtube.com/embed/${youtube_video_id}`}
          title={youtube_iframe_title}
          width="1110"
          height="620"
          frameBorder="0"
          allow={youtube_iframe_allow}
          allowFullScreen
        />
      </div>
    </>
  )
}

export default YouTubeEmbedded
