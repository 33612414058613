import React from "react"
import withSbEditable from "components/_bloks/withSbEditable"
import Link from "components/_common/Link"
import s from "./footer.module.scss"

const LinkComponent = ({ url, name }) => {
  const innerElement = url?.cached_url ? (
    <Link className={s.footerColumnLinkText} to={url.cached_url}>
      {name}
    </Link>
  ) : (
    <span className={s.footerColumnLinkText}>{name}</span>
  )

  return <span>{innerElement}</span>
}

export default withSbEditable(LinkComponent)
