import React from "react"
import withSbEditable from "components/_bloks/withSbEditable"
import Link from "components/_common/Link"
import s from "./singleItem.module.scss"

const SingleItem = ({ url, text }) => (
  <Link to={url?.cached_url} className={s.link} activeClassName={s.activeLink}>
    {text}
  </Link>
)

export default withSbEditable(SingleItem)
