import React from "react"
import withSbEditable from "components/_bloks/withSbEditable"
import Banner from "./Banner"
import Logo from "./Logo"
import Items from "./Items"
import Controls from "./Controls"
import s from "./topBar.module.scss"

const TopBar = ({
  logo,
  cta_name,
  cta_url,
  secondary_cta_name,
  secondary_cta_url,
  secondary_cta_type,
  items,
  additionalProps,
  variant,
}) => {
  const {
    location,
    onMenuButtonClick,
    banner,
    isBannerVisible,
    closeBanner,
    langLinks,
  } = additionalProps

  return (
    <nav className={s.topBar}>
      <Banner
        isVisible={isBannerVisible}
        onClose={closeBanner}
        banner={banner}
      />
      <div className={s.content}>
        <div className={s.logoContainer}>
          <Logo logo={logo} />
        </div>
        <Items items={items} />
        <Controls
          {...{
            cta_url,
            cta_name,
            secondary_cta_name,
            secondary_cta_url,
            secondary_cta_type,
            location,
            onMenuButtonClick,
            langLinks,
            variant,
          }}
        />
      </div>
    </nav>
  )
}

export default withSbEditable(TopBar)
