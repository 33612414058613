import React from "react"
import Wrapper from "components/_common/Wrapper"
import Markdown from "components/_common/Markdown"
import Renderer from "components/_common/Renderer"
import { RENDER } from "./consts"
import s from "./blogContent.module.scss"

export default function BlogContent({ content }) {
  const rendering =
    typeof content === "string" ? RENDER.MARKDOWN : RENDER.RICHTEXT

  return (
    <Wrapper className={s.blogContent} withoutDefaultPaddings>
      {rendering === RENDER.MARKDOWN && (
        <Markdown className={s.container}>{content}</Markdown>
      )}
      {rendering === RENDER.RICHTEXT && (
        <Renderer className={s.container} content={content} />
      )}
    </Wrapper>
  )
}
