import React from "react"
import isValidArray from "helpers/isValidArray"
import { ITEM_TYPES } from "components/_common/Page/Layout/TopBar/consts"
import SingleItem from "./SingleItem"
import MultipleItem from "./MultipleItem"
import s from "./items.module.scss"

export default function Items({ items }) {
  function renderItem(type, props) {
    switch (type) {
      case ITEM_TYPES.SINGLE:
        return <SingleItem key={props._uid} blok={props.link[0]} />
      case ITEM_TYPES.MULTIPLE:
        return (
          <MultipleItem
            key={props._uid}
            {...{ blok: props.link[0], ...props }}
          />
        )
      default:
        throw new Error("Unknown item type")
    }
  }

  if (!isValidArray(items)) {
    return null
  }

  return (
    <ul className={s.itemsWrapper}>
      {items.map(({ type, ...props }) => (
        <li key={props._uid}>{renderItem(type, props)}</li>
      ))}
    </ul>
  )
}
