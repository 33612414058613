import React from "react"
import IconButton from "@material-ui/core/IconButton"
import Icon from "@material-ui/core/Icon"
import Link from "components/_common/Link"
import s from "./banner.module.scss"

export default function Banner({ banner, isVisible, onClose }) {
  if (!isVisible) {
    return null
  }

  return (
    <aside className={s.banner}>
      <p className={s.bannerTextContainer}>
        <span className={s.bannerText}>{banner.text}</span>
        <Link className={s.bannerLink} to={banner.link_url?.cached_url}>
          {banner.link_text}
        </Link>
      </p>
      <div className={s.closeBannerButtonWrapper}>
        <IconButton
          className={s.closeBannerButton}
          aria-label="close"
          onClick={onClose}
          size="small"
        >
          <Icon>close</Icon>
        </IconButton>
      </div>
    </aside>
  )
}
