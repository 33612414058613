import React from "react"
import Wrapper from "components/_common/Wrapper"
import withSbEditable from "components/_bloks/withSbEditable"
import isValidArray from "helpers/isValidArray"
import ExpansionPanelsItem from "./ExpansionPanelsItem"
import s from "./expansionPanels.module.scss"

const ExpansionPanels = ({
  title,
  items,
  scroll_to_id,
  padding_top,
  padding_top_mb,
  padding_bottom,
  padding_bottom_mb,
  background_color,
}) => {
  if (!isValidArray(items)) {
    return null
  }

  return (
    <Wrapper
      id={scroll_to_id}
      paddingTop={padding_top}
      paddingTopMb={padding_top_mb}
      paddingBottom={padding_bottom}
      paddingBottomMb={padding_bottom_mb}
      style={{ background: background_color }}
    >
      <a href={`#${scroll_to_id}`}>
        <h3 className={s.headline}>{title}</h3>
      </a>
      {items.map(expansion_panels_item => (
        <ExpansionPanelsItem
          key={expansion_panels_item._uid}
          blok={expansion_panels_item}
        />
      ))}
    </Wrapper>
  )
}

export default withSbEditable(ExpansionPanels)
