import React from "react"
import Link from "components/_common/Link"
import Img from "components/_common/Image"
import withSbEditable from "components/_bloks/withSbEditable"
import s from "./tradedMaterials.module.scss"

const TradedMaterialsColumn = ({ image, link, title }) => {
  const isValidLink = !!(link && link.cached_url)

  const content = (
    <>
      <div className={s.logoWrapper}>
        <div className={s.logoInner}>
          <Img
            src={image}
            options={{
              maxWidth: 120,
            }}
            fluid
          />
        </div>
      </div>
      <span className={s.materialName}>{title}</span>
    </>
  )

  return (
    <>
      {isValidLink && (
        <Link to={link.cached_url} className={s.column}>
          {content}
        </Link>
      )}
      {!isValidLink && <div className={s.column}>{content}</div>}
    </>
  )
}

export default withSbEditable(TradedMaterialsColumn)
