import React from "react"
import cns from "classnames"
import Dropdown from "rc-dropdown"
import Menu, { Item } from "rc-menu"
import "rc-dropdown/assets/index.css"
import Link from "components/_common/Link"
import withSbEditable from "components/_bloks/withSbEditable"
import isValidArray from "helpers/isValidArray"
import NavigationItemDropdownLink from "./NavigationItemDropdownLink"
import s from "./multipleItem.module.scss"
import menuLabelArrow from "images/arrow_down_icon.svg"

const MultipleItem = ({ url, text, additionalProps }) => {
  const { dropdown_links, clickable_dropdown_root } = additionalProps

  if (!isValidArray(dropdown_links)) {
    return null
  }

  const labelComponent = clickable_dropdown_root ? (
    <Link
      to={url?.cached_url}
      className={cns(s.link, s.menuLabel, s.cursorPointer)}
    >
      {text}
      <img src={menuLabelArrow} className={s.menuLabelArrow} alt="" />
    </Link>
  ) : (
    <span className={s.menuLabel}>
      {text}
      <img src={menuLabelArrow} className={s.menuLabelArrow} alt="" />
    </span>
  )

  const overlayComponent = (
    <Menu>
      {dropdown_links.map(navigation_item_dropdown_link => (
        <Item key={navigation_item_dropdown_link._uid}>
          <NavigationItemDropdownLink
            key={navigation_item_dropdown_link._uid}
            blok={navigation_item_dropdown_link}
          />
        </Item>
      ))}
    </Menu>
  )

  return (
    <Dropdown overlay={overlayComponent} overlayClassName={s.list}>
      {labelComponent}
    </Dropdown>
  )
}

export default withSbEditable(MultipleItem)
