import React from "react"
import Renderer from "components/_common/Renderer"
import withSbEditable from "components/_bloks/withSbEditable"
import getPaddingProps from "helpers/getPaddingProps"
import useDefaultPaddings from "hooks/useDefaultPaddings"
import s from "./text.module.scss"

const Text = ({
  content,
  alignment,
  line_height,
  padding_top,
  padding_top_mb,
  padding_bottom,
  padding_bottom_mb,
  background_color
}) => {
  const paddings = useDefaultPaddings(
    {
      paddingTop: padding_top,
      paddingTopMb: padding_top_mb,
      paddingBottom: padding_bottom,
      paddingBottomMb: padding_bottom_mb,
    },
    { withoutDefaultPaddings: true }
  )

  return (
    <Renderer
      content={content}
      className={s.text}
      style={{
        background: background_color,
        textAlign: alignment || "left",
        lineHeight: line_height || "unset",
        ...getPaddingProps(paddings, {
          variableNamePrefix: "text-padding",
        }),
      }}
    />
  )
}

export default withSbEditable(Text)
