import React from "react"
import Link from "components/_common/Link"
import s from "./singleItem.module.scss"

export default function SingleItem({ link }) {
  const { url, text } = link

  return (
    <li className={s.link}>
      <Link to={url?.cached_url} activeClassName={s.activeLink}>
        {text}
      </Link>
    </li>
  )
}
