import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import { isValidArray, formatDateIntl } from "helpers"
import Wrapper from "components/_common/Wrapper"
import BackCta from "components/_common/BackCta"
import Img from "components/_common/Image"
import SocialButtons from "./SocialButtons"
import s from "./blogHeader.module.scss"

export default function BlogHeader({ blok, slug }) {
  const intl = useIntl()

  const {
    title,
    seo_description,
    tags,
    featured_image,
    date_published,
    introduction,
    categories,
  } = blok

  const mainCategory = isValidArray(categories) && categories[0].name

  return (
    <Wrapper className={s.blogHeader} withoutDefaultPaddings>
      <BackCta
        className={s.backCta}
        text={intl.formatMessage({ id: "Back to blog" })}
      />
      <div className={s.mainColumn}>
        <Img
          src={featured_image}
          options={{
            maxWidth: 520,
          }}
          className={s.image}
          fluid
        />
        <div className={s.content}>
          <h1 className={s.title}>{title}</h1>
          <p className={s.introduction}>{introduction}</p>
        </div>
      </div>
      <div className={s.metaColumn}>
        <span>{mainCategory}</span>
        <time className={s.datePublished}>
          {formatDateIntl(date_published, intl)}
        </time>
        <SocialButtons excerpt={seo_description} {...{ title, tags, slug }} />
      </div>
    </Wrapper>
  )
}
