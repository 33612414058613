import React from "react"
import withSbEditable from "components/_bloks/withSbEditable"
import s from "./steps.module.scss"

import StepIcon from "images/step_next_icon.svg"

const StepsItem = ({ title, additionalProps }) => {
  const { isLastItem } = additionalProps

  return (
    <>
      <div className={s.columnItem}>
        <img className={s.stepIcon} alt="step icon" src={StepIcon} />
        <div className={s.stepTitle}>{title}</div>
      </div>
      {!isLastItem && (
        <div className={s.separator}>
          <i />
        </div>
      )}
    </>
  )
}

export default withSbEditable(StepsItem)
