export const ASIDE_ALIGNMENTS = {
  LEFT: "left",
  RIGHT: "right",
}

export const MODES = {
  AUTOMATIC: "0",
  MANUAL: "1",
  MIXED: "2",
}
