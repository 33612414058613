import React, { useState, useRef } from "react"
import SbEditable from "storyblok-react"
import { useIntl } from "gatsby-plugin-intl"
import TextField from "@material-ui/core/TextField"
import Checkbox from "@material-ui/core/Checkbox"
import addToMailchimp from "gatsby-plugin-mailchimp"
import ReactNotification from "react-notifications-component"
import "react-notifications-component/dist/theme.css"
import Button from "components/_common/Button"
import { isValidEmail, addNotification } from "helpers"
import s from "./newsletter.module.scss"
import Wrapper from "components/_common/Wrapper"

// TODO: Refactor: entire component
export default function Newsletter({ blok }) {
  const {
    section_bg,
    heading,
    subheading,
    subscribe_button_text,
    consent_message,
    paragraph,
    cta_text,
    cta_link,
    input_placeholder,
    include_cta_column,
    centered,
  } = blok

  const intl = useIntl()
  const notificationDOMRef = useRef(null)
  const formRef = useRef(null)
  const [consentCheck, setconsentCheck] = useState(false)
  const [email, setEmail] = useState("")

  const paddingTop = include_cta_column ? blok.ctaPaddingTop : blok.paddingTop
  const paddingBottom = include_cta_column
    ? blok.ctaPaddingBottom
    : blok.paddingBottom

  function handleFormSubmit(e) {
    e.preventDefault()
    if (consentCheck && isValidEmail(email)) {
      addNotification(
        `${email} Subscribed Successfully`,
        "success",
        notificationDOMRef
      )
      // TODO: We need ask to client for a mailchimp list for test purpose
      addToMailchimp(email)
        .then(data => {
          if (
            typeof window !== "undefined" &&
            typeof window.dataLayer !== "undefined"
          ) {
            window.dataLayer.push({ event: "mailchimpFormSent" })
          }
          addNotification(data.msg, data.result)
        })
        .catch(() => {
          // unnecessary because Mailchimp only ever
          // returns a 200 status code
        })
      formRef.current.reset()
      setEmail(null)
      setconsentCheck(false)
    } else if (consentCheck && !isValidEmail(email)) {
      addNotification(
        intl.formatMessage({
          id: "The e-mail address you have entered does not seem to be valid",
        }),
        "danger",
        notificationDOMRef
      )
    } else {
      addNotification(
        intl.formatMessage({
          id: "You have to check the fields and agree the terms",
        }),
        "danger",
        notificationDOMRef
      )
    }
  }

  return (
    <SbEditable content={blok}>
      <Wrapper
        backgroundImage={include_cta_column && section_bg.filename}
        className={include_cta_column ? s.sectionBg : s.wrapperWithoutCtaColumn}
        {...{ paddingTop, paddingBottom }}
      >
        <ReactNotification ref={notificationDOMRef} />
        <div className={s.newsletterContainer}>
          <div className={s.grid}>
            <div
              className={s.subscribeContent}
              style={{ justifyContent: centered ? "center" : "flex-start" }}
            >
              <div className={s.headingBox}>
                <h3 className={s.heading}>{heading}</h3>
                <p className={s.subHeading}>{subheading}</p>
              </div>
              <div>
                <form ref={formRef} onSubmit={handleFormSubmit}>
                  <div className={s.inputWrapper}>
                    <TextField
                      className={s.inputFieldWrapper}
                      inputProps={{
                        "aria-label": "email input field",
                      }}
                      name="email"
                      value={email}
                      placeholder={input_placeholder}
                      variant={!include_cta_column ? "outlined" : "standard"}
                      required={true}
                      onChange={e => setEmail(e.target.value)}
                    />
                    <Button
                      type="submit"
                      color="dark"
                      size="small"
                      text={subscribe_button_text}
                      mobileFullWidth={true}
                      className={s.subscribeButton}
                    />
                  </div>
                  <div className={s.consentCheckbox}>
                    <input
                      type="checkbox"
                      color="primary"
                      error="true"
                      checked={consentCheck}
                      onChange={() =>
                        setconsentCheck(consentCheck => !consentCheck)
                      }
                      inputProps={{
                        "aria-label": "primary checkbox",
                      }}
                    />
                    <small className={s.consentMessage}>
                      {consent_message}
                    </small>
                  </div>
                </form>
              </div>
            </div>
            {include_cta_column && (
              <div className={s.ctaInfoWrapper}>
                <div className={s.ctaInfoWrapperInner}>
                  <p>{paragraph}</p>
                  {cta_text && cta_link?.cached_url && (
                    <Button
                      text={cta_text}
                      link={cta_link.cached_url}
                      mobileFullWidth={true}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </Wrapper>
    </SbEditable>
  )
}
