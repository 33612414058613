export default function formatDateIntl(date, intl) {
  if (!date || !intl) {
    return null
  }

  const parsedDate = Date.parse(date.split(" ")[0])
  return intl.formatDate(parsedDate, {
    year: "numeric",
    month: "long",
    day: "2-digit",
  })
}
