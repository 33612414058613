import React from "react"
import { isValidArray, getPaddingProps } from "helpers"
import Wrapper from "components/_common/Wrapper"
import withSbEditable from "components/_bloks/withSbEditable"
import useDefaultPaddings from "hooks/useDefaultPaddings"
import Head from "./components/Head"
import Body from "./components/Body"
import s from "./table.module.scss"

const Table = ({
  content,
  padding_top,
  padding_top_mb,
  padding_bottom,
  padding_bottom_mb,
  additionalProps,
  background_color,
}) => {
  const { column } = additionalProps

  const paddings = useDefaultPaddings({
    paddingTop: padding_top,
    paddingTopMb: padding_top_mb,
    paddingBottom: padding_bottom,
    paddingBottomMb: padding_bottom_mb,
  })

  if (!isValidArray(content.thead) || !isValidArray(content.tbody)) {
    return null
  }

  function Table({ headColumns, bodyRows }) {
    return (
      <div className={s.container}>
        <table className={s.table}>
          <Head columns={headColumns} />
          <Body style={{ background: background_color }} rows={bodyRows} />
        </table>
      </div>
    )
  }

  if (column) {
    return (
      <div
        className={s.column}
        style={{
          ...getPaddingProps(paddings),
        }}
      >
        <Table headColumns={content.thead} bodyRows={content.tbody} />
      </div>
    )
  }

  return (
    <Wrapper
      paddingTop={padding_top}
      paddingTopMb={padding_top_mb}
      paddingBottom={padding_bottom}
      paddingBottomMb={padding_bottom_mb}
    >
      <Table headColumns={content.thead} bodyRows={content.tbody} />
    </Wrapper>
  )
}

export default withSbEditable(Table)
