import React from "react"
import cns from "classnames"
import GatsbyImage from "gatsby-image"
import { Helmet } from "react-helmet"
import SbEditable from "storyblok-react"
import Wrapper from "components/_common/Wrapper"
import CtaButton from "components/_common/CtaButton"
import { getCtaButtonProps } from "components/_common/CtaButton/helpers"
import withSbEditable from "components/_bloks/withSbEditable"
import useStoryblokImage, { IMAGE_TYPES } from "hooks/useStoryblokImage"
import isValidArray from "helpers/isValidArray"
import s from "./header.module.scss"
import sc from "../header.common.module.scss"
import { selectHeadingTextVariant } from "helpers"

const Header = ({
  background_image,
  heading,
  subheading,
  cta,
  has_contact_corner,
  contact_cornet_text,
  contact_corner_tel,
  background_color,
  text_color,
}) => {
  const fluidProps = useStoryblokImage(background_image, IMAGE_TYPES.FLUID, {
    maxWidth: 1440,
  })

  if (!fluidProps) {
    return null
  }

  return (
    <div className={cns(s.inner, selectHeadingTextVariant(text_color, sc))} style={{ background: background_color }}>
      <GatsbyImage
        fluid={fluidProps}
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          width: "100%",
          height: "100%",
          zIndex: "-1",
        }}
        loading="eager"
        fadeIn={false}
      />
      <Helmet>
        <link
          rel="preload"
          as="image"
          href={fluidProps.srcWebp}
          imagesizes={fluidProps.sizes}
          imagesrcset={fluidProps.srcSetWebp}
        />
      </Helmet>
      <Wrapper
        className={has_contact_corner ? s.wrapperWithCorner : ""}
        withoutDefaultPaddings
      >
        <div className={s.content}>
          <h1>{heading}</h1>
          <h2>{subheading}</h2>
          <div className={s.cta}>
            {isValidArray(cta) &&
              cta.map(cta_button => {
                return cta_button.url_v2?.cached_url ? (
                  <SbEditable key={cta_button._uid} content={cta_button}>
                    <CtaButton {...getCtaButtonProps(cta_button)} />
                  </SbEditable>
                ) : null
              })}
          </div>
        </div>
        {has_contact_corner && (
          <div className={s.contactCorner}>
            {contact_cornet_text && <p>{contact_cornet_text}</p>}
            {contact_corner_tel && (
              <a href={`tel:${contact_corner_tel}`}>{contact_corner_tel}</a>
            )}
          </div>
        )}
      </Wrapper>
    </div>
  )
}

export default withSbEditable(Header)
