import React from "react"
import isValidArray from "helpers/isValidArray"
import SectionHeading from "components/_common/SectionHeading"
import Wrapper from "components/_common/Wrapper"
import withSbEditable from "components/_bloks/withSbEditable"
import RoutingColumn from "./RoutingColumn"
import s from "./routing.module.scss"

const Routing = ({
  heading,
  columns,
  padding_top,
  padding_top_mb,
  padding_bottom,
  padding_bottom_mb,
}) => {
  if (!isValidArray(columns)) {
    return null
  }

  return (
    <Wrapper
      paddingTop={padding_top}
      paddingTopMb={padding_top_mb}
      paddingBottom={padding_bottom}
      paddingBottomMb={padding_bottom_mb}
    >
      <SectionHeading text={heading} />
      <div className={s.wrapper}>
        {columns.map(routing_column => (
          <RoutingColumn key={routing_column._uid} blok={routing_column} />
        ))}
      </div>
    </Wrapper>
  )
}

export default withSbEditable(Routing)
