import React from "react"
import cns from "classnames"
import Link from "components/_common/Link"
import isValidArray from "helpers/isValidArray"
import s from "./multipleItem.module.scss"

export default function MultipleItem({
  link,
  dropdown_links,
  isOpen,
  onRootLinkClick,
}) {
  const { _uid, text } = link[0]

  function handleClick() {
    onRootLinkClick(_uid, link, dropdown_links)
  }

  return (
    <li>
      <span
        className={cns(s.dropDownLabel, isOpen && s.dropDownLabelOpen)}
        onClick={handleClick}
      >
        {text}
      </span>
      <ul
        className={cns(s.dropDownContainer, isOpen && s.dropDownContainerOpen)}
      >
        {isValidArray(dropdown_links) &&
          dropdown_links.map(dropDownLink => (
            <li key={dropDownLink._uid} className={s.dropDownLink}>
              <Link
                to={dropDownLink.url?.cached_url}
                activeClassName={s.activeDropDownLink}
                type={dropDownLink.type}
              >
                {dropDownLink.text}
              </Link>
            </li>
          ))}
      </ul>
    </li>
  )
}
