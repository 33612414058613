import React from "react"
import cns from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLinkedin } from "@fortawesome/free-brands-svg-icons"
import Img from "components/_common/Image"
import withSbEditable from "components/_bloks/withSbEditable"
import s from "./teamMembersColumn.module.scss"

const TeamMembersColumn = ({
  image,
  title,
  subtitle,
  linkedin,
  description,
  additionalProps,
}) => {
  const { isWider } = additionalProps

  function handleClick() {
    window.open(linkedin)
  }

  return (
    <li
      className={cns(
        s.container,
        isWider ? s.widerContainer : s.narrowerContainer
      )}
    >
      <Img
        src={image}
        options={{
          width: isWider ? 200 : 160,
        }}
        className={s.image}
        fixed
      />
      <span className={s.title}>{title}</span>
      <span className={s.subtitle}>{subtitle}</span>
      {linkedin ? (
        <FontAwesomeIcon
          className={s.linkedIn}
          icon={faLinkedin}
          size="lg"
          onClick={handleClick}
        />
      ) : (
        <div className={s.linkedInPlaceholder} />
      )}
      {description && (
        <p className={cns(s.description, isWider && s.widerDescription)}>
          {description}
        </p>
      )}
    </li>
  )
}

export default withSbEditable(TeamMembersColumn)
