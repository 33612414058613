import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import BlogIntroduction from "components/_common/BlogIntroduction"
import { formatDateIntl } from "helpers"
import mockData from "./mock-data.json"
import s from "./blogPostLink.module.scss"

// Component used as a placeholder inside Storyblok Live Editor
export default function EditorBlogPostLink() {
  const intl = useIntl()

  return (
    <article className={s.blogPostLink}>
      <div className={s.imagePlaceholder} />
      <div className={s.content}>
        <h4 className={s.title}>{mockData.title}</h4>
        <span className={s.category}>{mockData.main_category}</span>
        <time className={s.date}>
          {formatDateIntl(mockData.date_published, intl)}
        </time>
        <div className={s.introduction}>
          <BlogIntroduction introduction={mockData.introduction} />
        </div>
      </div>
    </article>
  )
}
