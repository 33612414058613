import React from "react"
import GatsbyImage from "gatsby-image"
import SbEditable from "storyblok-react"
import { Helmet } from "react-helmet"
import cns from "classnames"
import withSbEditable from "components/_bloks/withSbEditable"
import Wrapper from "components/_common/Wrapper"
import CtaButton from "components/_common/CtaButton"
import { isValidArray, getPaddingProps, getSidePaddingProps } from "helpers"
import { getCtaButtonProps } from "components/_common/CtaButton/helpers"
import useStoryblokImage, { IMAGE_TYPES } from "hooks/useStoryblokImage"
import useDefaultPaddings from "hooks/useDefaultPaddings"
import { CONTENT_ALIGNMENTS } from "./consts"
import s from "./header.module.scss"
import sc from "../header.common.module.scss"
import { selectHeadingTextVariant } from "helpers"

const Header = ({
  heading,
  subheading,
  background_image,
  cta_buttons,
  contact_corner_enabled,
  contact_corner_text,
  contact_corner_tel,
  content_alignment,
  padding_top,
  padding_top_mb,
  padding_bottom,
  padding_bottom_mb,
  padding_left,
  padding_left_mb,
  padding_right,
  padding_right_mb,
  heading_padding_top,
  heading_padding_top_mb,
  heading_padding_bottom,
  heading_padding_bottom_mb,
  subheading_padding_top,
  subheading_padding_top_mb,
  subheading_padding_bottom,
  subheading_padding_bottom_mb,
  cta_buttons_padding_top,
  cta_buttons_padding_top_mb,
  cta_buttons_padding_bottom,
  cta_buttons_padding_bottom_mb,
  contact_corner_padding_top,
  contact_corner_padding_top_mb,
  contact_corner_padding_bottom,
  contact_corner_padding_bottom_mb,
  background_color,
  text_color,
}) => {
  const fluidProps = useStoryblokImage(
    background_image.filename,
    IMAGE_TYPES.FLUID,
    {
      maxWidth: 1440,
    }
  )

  const headingPaddings = useDefaultPaddings(
    {
      paddingTop: heading_padding_top,
      paddingTopMb: heading_padding_top_mb,
      paddingBottom: heading_padding_bottom,
      paddingBottomMb: heading_padding_bottom_mb,
    },
    { withoutDefaultPaddings: true }
  )

  const subheadingPaddings = useDefaultPaddings(
    {
      paddingTop: subheading_padding_top,
      paddingTopMb: subheading_padding_top_mb,
      paddingBottom: subheading_padding_bottom,
      paddingBottomMb: subheading_padding_bottom_mb,
    },
    { withoutDefaultPaddings: true }
  )

  const ctaButtonsPaddings = useDefaultPaddings(
    {
      paddingTop: cta_buttons_padding_top,
      paddingTopMb: cta_buttons_padding_top_mb,
      paddingBottom: cta_buttons_padding_bottom,
      paddingBottomMb: cta_buttons_padding_bottom_mb,
    },
    { withoutDefaultPaddings: true }
  )

  const contactCornerPaddings = useDefaultPaddings(
    {
      paddingTop: contact_corner_padding_top,
      paddingTopMb: contact_corner_padding_top_mb,
      paddingBottom: contact_corner_padding_bottom,
      paddingBottomMb: contact_corner_padding_bottom_mb,
    },
    { withoutDefaultPaddings: true }
  )

  const contactCornerAllowed = !!(
    contact_corner_enabled &&
    contact_corner_text &&
    contact_corner_tel
  )

  function renderBackgroundImage() {
    return (
      <>
        <GatsbyImage
          fluid={fluidProps}
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
            zIndex: "-1",
          }}
          loading="eager"
          fadeIn={false}
        />
        <Helmet>
          <link
            rel="preload"
            as="image"
            href={fluidProps.srcWebp}
            imagesizes={fluidProps.sizes}
            imageSrcSet={fluidProps.srcSetWebp}
          />
        </Helmet>
      </>
    )
  }

  function renderContainer() {
    function renderContainerCtaButtons() {
      return (
        <div
          className={cns(s.headerContainerCtaButtons, {
            [s.headerContainerCtaButtonsLeft]:
              content_alignment === CONTENT_ALIGNMENTS.LEFT,
            [s.headerContainerCtaButtonsCenter]:
              content_alignment === CONTENT_ALIGNMENTS.CENTER,
            [s.headerContainerCtaButtonsRight]:
              content_alignment === CONTENT_ALIGNMENTS.RIGHT,
          })}
          style={{
            ...getPaddingProps(ctaButtonsPaddings, {
              variableNamePrefix: "cta-buttons-padding",
            }),
          }}
        >
          {cta_buttons.map(ctaButton => (
            <SbEditable key={ctaButton._uid} content={ctaButton}>
              <CtaButton
                {...getCtaButtonProps(ctaButton)}
                className={s.ctaButton}
              />
            </SbEditable>
          ))}
        </div>
      )
    }

    return (
      <div className={s.headerContainer}>
        <div>
          <h1
            className={cns(s.headerContainerHeading, {
              [s.headerContainerHeadingLeft]:
                content_alignment === CONTENT_ALIGNMENTS.LEFT,
              [s.headerContainerHeadingCenter]:
                content_alignment === CONTENT_ALIGNMENTS.CENTER,
              [s.headerContainerHeadingRight]:
                content_alignment === CONTENT_ALIGNMENTS.RIGHT,
            })}
            style={{
              ...getPaddingProps(headingPaddings, {
                variableNamePrefix: "heading-padding",
              }),
            }}
          >
            {heading}
          </h1>
          <h2
            className={cns(s.headerContainerSubheading, {
              [s.headerContainerSubheadingLeft]:
                content_alignment === CONTENT_ALIGNMENTS.LEFT,
              [s.headerContainerSubheadingCenter]:
                content_alignment === CONTENT_ALIGNMENTS.CENTER,
              [s.headerContainerSubheadingRight]:
                content_alignment === CONTENT_ALIGNMENTS.RIGHT,
            })}
            style={{
              ...getPaddingProps(subheadingPaddings, {
                variableNamePrefix: "subheading-padding",
              }),
            }}
          >
            {subheading}
          </h2>
        </div>
        {isValidArray(cta_buttons) && renderContainerCtaButtons()}
      </div>
    )
  }

  function renderContactCorner() {
    return (
      <div
        className={cns(s.headerContactCorner, {
          [s.headerContactCornerLeft]:
            content_alignment === CONTENT_ALIGNMENTS.LEFT,
          [s.headerContactCornerCenter]:
            content_alignment === CONTENT_ALIGNMENTS.CENTER,
          [s.headerContactCornerRight]:
            content_alignment === CONTENT_ALIGNMENTS.RIGHT,
        })}
        style={{
          ...getPaddingProps(contactCornerPaddings, {
            variableNamePrefix: "contact-corner-padding",
          }),
        }}
      >
        <div>
          <span className={s.headerContactCornerText}>
            {contact_corner_text}
          </span>
          <a
            className={s.headerContactCornerTel}
            href={`tel:${contact_corner_tel}`}
          >
            {contact_corner_tel}
          </a>
        </div>
      </div>
    )
  }

  if (!fluidProps) {
    return null
  }

  return (
    <div className={cns(s.header, selectHeadingTextVariant(text_color, sc))} style={{ background: background_color }}>
      {renderBackgroundImage()}
      <Wrapper
        paddingTop={padding_top}
        paddingTopMb={padding_top_mb}
        paddingBottom={padding_bottom}
        paddingBottomMb={padding_bottom_mb}
        className={cns(
          contactCornerAllowed && s.headerWrapperWithContactCorner,
          s.headerWrapperWithSidePaddings
        )}
        // TODO: Incorporate side paddings into common Wrapper component and update all bloks to support it
        style={{
          ...getSidePaddingProps({
            paddingLeft: padding_left,
            paddingLeftMb: padding_left_mb,
            paddingRight: padding_right,
            paddingRightMb: padding_right_mb,
          }),
        }}
      >
        {renderContainer()}
        {contactCornerAllowed && renderContactCorner()}
      </Wrapper>
    </div>
  )
}

export default withSbEditable(Header)
