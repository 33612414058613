import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import { truncateString } from "./helpers"
import s from "./blogIntroduction.module.scss"

export default function BlogIntroduction({ introduction }) {
  const intl = useIntl()

  return (
    <p>
      {truncateString(introduction)}{" "}
      <span className={s.action}>
        {intl.formatMessage({ id: "read more" })}
      </span>
    </p>
  )
}
