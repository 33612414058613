import React from "react"
import cns from "classnames"
import Markdown from "components/_common/Markdown"
import Img from "components/_common/Image"
import withSbEditable from "components/_bloks/withSbEditable"
import s from "./quotes.module.scss"

const RichQuote = ({
  person_avatar,
  aside_position,
  person_name,
  person_position,
  person_company,
  quote,
  aside_heading,
}) => {
  return (
    <blockquote
      className={cns(
        s.blockquoteBox,
        aside_position === "left" && s.positionLeft
      )}
    >
      <div className={s.citeBox}>
        <Img
          src={person_avatar}
          options={{
            maxWidth: 100,
          }}
          className={s.citeAvatar}
          fluid
        />
        <cite>
          <p className={s.citeName}>{person_name}</p>
          <p className={s.citePosition}>{person_position}</p>
          <p className={s.citeCompany}>{person_company}</p>
        </cite>
      </div>

      <div className={s.quote}>
        <Markdown>{quote}</Markdown>
      </div>
      {aside_heading && (
        <div className={s.asideBox}>
          <h4 className={s.asideHeading}>{aside_heading}</h4>
        </div>
      )}
    </blockquote>
  )
}

export default withSbEditable(RichQuote)
