import React from "react"
import GatsbyImage from "gatsby-image"
import cns from "classnames"
import useStoryblokImage, { IMAGE_TYPES } from "hooks/useStoryblokImage"
import { getImageProps } from "./helpers"

export default function Image({
  src: image,
  fluid,
  fixed,
  alt,
  title,
  className,
  style = {},
  options = {},
  ...rest
}) {
  const type = (fluid && IMAGE_TYPES.FLUID) || (fixed && IMAGE_TYPES.FIXED)

  const gatsbyStoryblokImageProps = useStoryblokImage(image, type, options)

  if (!fluid && !fixed) {
    throw new Error("Incorrect use of the Image component")
  }

  if (!gatsbyStoryblokImageProps) {
    return null
  }

  return (
    <GatsbyImage
      fluid={(fluid && gatsbyStoryblokImageProps) || undefined}
      fixed={(fixed && gatsbyStoryblokImageProps) || undefined}
      style={style}
      className={cns(className)}
      {...getImageProps({ alt, title })}
      {...rest}
    />
  )
}
