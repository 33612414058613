import React from "react"
import withSbEditable from "components/_bloks/withSbEditable"
import Img from "components/_common/Image"
import s from "./indices.module.scss"

const IndicesColumn = ({ image, name }) => {
  return (
    <div className={s.column}>
      <div className={s.imageWrapper}>
        <div className={s.imageInner}>
          <Img
            src={image}
            options={{
              width: 142,
            }}
            fixed
          />
        </div>
        <h4 className={s.indiceName}>{name}</h4>
      </div>
    </div>
  )
}

export default withSbEditable(IndicesColumn)
