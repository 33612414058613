import React from "react"
import Wrapper from "components/_common/Wrapper"
import CtaButton from "components/_common/CtaButton"
import withSbEditable from "components/_bloks/withSbEditable"
import isValidArray from "helpers/isValidArray"
import { BUTTON_VARIANTS } from "consts"
import s from "./statistics.module.scss"
import StatisticsColumn from "./StatisticsColumn"

const Statistics = ({
  columns,
  cta_name,
  cta_url_v2,
  link_type_v2,
  padding_top,
  padding_top_mb,
  padding_bottom,
  padding_bottom_mb,
}) => {
  if (!isValidArray(columns)) {
    return null
  }

  return (
    <Wrapper
      paddingTop={padding_top}
      paddingTopMb={padding_top_mb}
      paddingBottom={padding_bottom}
      paddingBottomMb={padding_bottom_mb}
    >
      <div className={s.inner}>
        {columns.map(statistics_column => (
          <StatisticsColumn
            key={statistics_column._uid}
            blok={statistics_column}
          />
        ))}
      </div>
      <div className={s.cta}>
        {cta_name && cta_url_v2?.cached_url && (
          <CtaButton
            text={cta_name}
            link={cta_url_v2.cached_url}
            type={link_type_v2}
            variant={BUTTON_VARIANTS.ORANGE}
          />
        )}
      </div>
    </Wrapper>
  )
}

export default withSbEditable(Statistics)
