/**
 * Ensure all slugs end with a forward slash
 *
 * @param {String} slug
 *
 * @returns {String | undefined}
 */
function handleTrailingSlash(slug) {
  if (!slug) return
  return slug.endsWith("/") ? slug : slug.replace(/\/?$/, "/")
}

export default handleTrailingSlash
