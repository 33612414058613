import { useMemo } from "react"
import {
  getFixedGatsbyImage,
  getFluidGatsbyImage,
} from "gatsby-storyblok-image"

export const IMAGE_TYPES = {
  FLUID: "fluid",
  FIXED: "fixed",
}

const DEFAULT_FLUID_IMAGE_OPTIONS = {
  maxWidth: null,
  maxHeight: null,
}

const DEFAULT_FIXED_IMAGE_OPTIONS = {
  width: null,
  height: null,
}

const DEFAULT_IMAGE_OPTIONS = {
  ...DEFAULT_FLUID_IMAGE_OPTIONS,
  ...DEFAULT_FIXED_IMAGE_OPTIONS,
}

function isValidType(type) {
  return (
    typeof type === "string" &&
    (type === IMAGE_TYPES.FLUID || type === IMAGE_TYPES.FIXED)
  )
}

function isValidImage(image) {
  return typeof image === "string"
}

export default function useStoryblokImage(
  image,
  type,
  options = DEFAULT_IMAGE_OPTIONS
) {
  const imageExists = !!image

  if (imageExists && !isValidImage(image)) {
    throw new Error("Invalid image argument provided")
  }

  if (!isValidType(type)) {
    throw new Error("Invalid type argument provided")
  }

  const imageProps = useMemo(() => {
    if (imageExists) {
      switch (type) {
        case IMAGE_TYPES.FLUID:
          return getFluidGatsbyImage(image, options)
        case IMAGE_TYPES.FIXED:
          return getFixedGatsbyImage(image, options)
        default:
          throw new Error("Unexpected hook case")
      }
    }
    return null
  }, [image, type, imageExists, options])

  return imageProps
}
