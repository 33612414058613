import React from "react"
import withSbEditable from "components/_bloks/withSbEditable"
import Link from "./Link"
import s from "./footer.module.scss"

const FooterColumn = ({ title, links }) => {
  return (
    <div>
      <h4 className={s.footerColumnTitle}>{title}</h4>
      <ul className={s.footerColumnLinks}>
        {links.map(link => (
          <li key={link._uid} className={s.footerColumnLink}>
            <Link blok={link} />
          </li>
        ))}
      </ul>
    </div>
  )
}

export default withSbEditable(FooterColumn)
