import React, { useEffect } from "react"
import { Helmet } from "react-helmet"
import { ThemeProvider } from "@material-ui/styles"
import PropTypes from "prop-types"
import Footer from "components/_common/Page/Layout/Footer"
import TopBar from "components/_common/Page/Layout/TopBar"
import { THEME } from "./consts"
import Main from "./Main"
import s from "./layout.module.scss"

export default function Layout({
  banner,
  footerBlok,
  navigationBlok,
  slug,
  path,
  whiteBackground,
  isPaginating,
  langLinks,
  children,
}) {
  // triggers on load events in order to swap cookiebot tags on HTML elements
  // and that way preserve site's general functionality
  useEffect(() => {
    if (window.Cookiebot) {
      window.Cookiebot.triggerOnloadEvents()
    }
  }, [])

  return (
    <>
      <Helmet
        bodyAttributes={{
          class: whiteBackground ? s.bodyWhite : s.bodyGrey,
        }}
      />
      <ThemeProvider theme={THEME}>
        {typeof navigationBlok === "object" && (
          <TopBar
            blok={navigationBlok}
            location={slug}
            {...{ path, banner, isPaginating, langLinks }}
          />
        )}
        <Main>{children}</Main>
        {typeof footerBlok === "object" && <Footer blok={footerBlok} />}
      </ThemeProvider>
    </>
  )
}

Layout.propTypes = {
  slug: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  banner: PropTypes.shape({
    enabled: PropTypes.bool,
    text: PropTypes.string.isRequired,
    link_text: PropTypes.string.isRequired,
    link_url: PropTypes.object.isRequired,
    visible_during_pagination: PropTypes.bool,
  }),
  footer: PropTypes.object,
  navigation: PropTypes.object,
  whiteBackground: PropTypes.bool,
  isPaginating: PropTypes.bool,
  langLinks: PropTypes.arrayOf(PropTypes.string),
}
