import React from "react"
import Markdown from "markdown-to-jsx"

export default function MarkdownComponent({ children, ...rest }) {
  return (
    <Markdown options={{ forceBlock: true }} {...rest}>
      {children}
    </Markdown>
  )
}
