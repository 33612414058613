import React, { useState } from "react"
import Switch from "react-switch"
import { COLORS } from "consts"
import isValidArray from "helpers/isValidArray"
import withSbEditable from "components/_bloks/withSbEditable"
import Wrapper from "components/_common/Wrapper"
import SectionHeading from "components/_common/SectionHeading"
import ChoosePlanColumn from "./ChoosePlanColumn"
import s from "./choosePlan.module.scss"

const ChoosePlan = ({
  heading,
  subheading,
  plans,
  yearly_label,
  monthly_label,
  cta_description,
  padding_top,
  padding_top_mb,
  padding_bottom,
  padding_bottom_mb,
}) => {
  const [yearly, setYearly] = useState(false)

  function handleChange() {
    setYearly(!yearly)
  }

  const switchAriaLabel = `switch to ${
    yearly ? "monthly" : "yearly"
  } billing button`

  if (!isValidArray(plans)) {
    return null
  }

  return (
    <Wrapper
      className={s.wrapper}
      paddingTop={padding_top}
      paddingTopMb={padding_top_mb}
      paddingBottom={padding_bottom}
      paddingBottomMb={padding_bottom_mb}
    >
      <SectionHeading text={heading} />
      <p className={s.subheading}>{subheading}</p>
      <div className={s.content}>
        {plans.map(choose_plan_column => (
          <ChoosePlanColumn
            key={choose_plan_column._uid}
            blok={choose_plan_column}
            yearly={yearly}
          />
        ))}
      </div>
      <div className={s.switch}>
        <div className={s.switchLabel} is_bold={`${!yearly}`}>
          {monthly_label}
        </div>
        <Switch
          aria-label={switchAriaLabel}
          onChange={handleChange}
          checked={yearly}
          checkedIcon={false}
          uncheckedIcon={false}
          onHandleColor={COLORS.MH_ORANGE}
          offHandleColor={COLORS.MH_ORANGE}
          onColor="#F1D397"
          offColor="#F1D397"
          height={16}
          width={36}
          handleDiameter={20}
        />
        <div className={s.switchLabel} is_bold={`${yearly}`}>
          {yearly_label}
        </div>
      </div>
      <div className={s.switchTitle}>{cta_description}</div>
    </Wrapper>
  )
}

export default withSbEditable(ChoosePlan)
