import React from "react"
import SectionHeading from "components/_common/SectionHeading"
import Wrapper from "components/_common/Wrapper"
import withSbEditable from "components/_bloks/withSbEditable"
import isValidArray from "helpers/isValidArray"
import { TEXT_VARIANTS } from "consts"
import BlogCtaColumn from "./BlogCtaColumn"
import s from "./blogCta.module.scss"

const BlogCta = ({
  heading,
  columns,
  background_image,
  paddingTop,
  paddingTopMb,
  paddingBottom,
  paddingBottomMb,
}) => {
  if (!isValidArray(columns)) {
    return null
  }

  return (
    <Wrapper
      backgroundImage={background_image}
      paddingTop={paddingTop}
      paddingTopMb={paddingTopMb}
      paddingBottom={paddingBottom}
      paddingBottomMb={paddingBottomMb}
    >
      {heading && (
        <SectionHeading
          className={s.heading}
          variant={TEXT_VARIANTS.WHITE}
          text={heading}
        />
      )}
      <div className={s.columns} style={{ marginTop: heading ? "64px" : "0" }}>
        {columns.map(blog_cta_column => (
          <BlogCtaColumn key={blog_cta_column._uid} blok={blog_cta_column} />
        ))}
      </div>
    </Wrapper>
  )
}

export default withSbEditable(BlogCta)
