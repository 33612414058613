const DIVIDER = "/"

export default function getPathFromSlug(slug) {
  if (typeof slug !== "string") {
    return ""
  }

  let path = slug
  const missingStartForwardSlash = path.charAt(0) !== DIVIDER
  path = missingStartForwardSlash ? `/${path}` : path
  const endsWithForwardSlash = path.slice(-1) === DIVIDER
  path = endsWithForwardSlash ? path.slice(0, -1) : path

  return path
}
