import React from "react"
import cns from "classnames"
import withSbEditable from "components/_bloks/withSbEditable"
import Renderer from "components/_common/Renderer"
import Img from "components/_common/Image"
import { ASIDE_ALIGNMENTS } from "./consts"
import s from "./imageQuotes.module.scss"

const ImageQuotesItem = ({
  quote,
  cite_name,
  cite_position,
  cite_company,
  cite_image,
  aside_heading,
  aside_alignment,
}) => {
  const hasCite =
    cite_image.filename || cite_name || cite_position || cite_company

  function getQuoteBoxBorderClassNames() {
    if (aside_alignment === ASIDE_ALIGNMENTS.LEFT) {
      return {
        [s.quoteBoxTopBorder]: hasCite,
        [s.quoteBoxBottomBorder]: !!aside_heading,
      }
    } else if (aside_alignment === ASIDE_ALIGNMENTS.RIGHT) {
      return {
        [s.quoteBoxTopBorder]: !!aside_heading,
        [s.quoteBoxBottomBorder]: hasCite,
      }
    }

    throw new Error("Invalid aside alignment value")
  }

  return (
    <blockquote
      className={cns(
        s.blockquoteBox,
        aside_alignment === ASIDE_ALIGNMENTS.LEFT && s.alignLeft
      )}
    >
      {hasCite && (
        <div className={s.citeBox}>
          <Img
            src={cite_image.filename}
            options={{
              width: 64,
            }}
            className={s.citeImage}
            fixed
          />
          <cite>
            {cite_name && <p className={s.citeName}>{cite_name}</p>}
            {cite_position && <p className={s.citePosition}>{cite_position}</p>}
            {cite_company && <p className={s.citeCompany}>{cite_company}</p>}
          </cite>
        </div>
      )}
      <div
        className={cns(s.quoteBox, {
          ...getQuoteBoxBorderClassNames(),
        })}
      >
        <Renderer className={s.quote} content={quote} />
      </div>
      {aside_heading && (
        <div className={s.asideBox}>
          <h4 className={s.asideHeading}>{aside_heading}</h4>
        </div>
      )}
    </blockquote>
  )
}

export default withSbEditable(ImageQuotesItem)
