import React from "react"
import { render } from "storyblok-rich-text-react-renderer"
import { RESOLVERS } from "./consts"
import s from "./renderer.module.scss"

export default function Renderer({ content, className, ...rest }) {
  const processedContent = render(content, RESOLVERS)

  return (
    <div className={className || s.container} {...rest}>
      {processedContent}
    </div>
  )
}
