import React from "react"
import s from "./flexibleTextColumns.module.scss"

const Column = ({ heading, body, heading_color, body_color, heading_size }) => {
  const Heading = heading_size || "h3"

  return (
    <div className={s.column}>
      <Heading
        className={s.columnHeading}
        style={{ "--heading-color": heading_color }}
      >
        {heading}
      </Heading>
      <p className={s.columnBody} style={{ "--body-color": body_color }}>
        {body}
      </p>
    </div>
  )
}

export default Column
