import React, { useState } from "react"
import { useIntl } from "gatsby-plugin-intl"
import Icon from "@material-ui/core/Icon"
import { createPortal } from "react-dom"
import IconButton from "@material-ui/core/IconButton"
import cns from "classnames"
import Button from "components/_common/Button"
import Backdrop from "components/_common/Backdrop"
import pushGTMEvent from "helpers/pushGTMEvent"
import { COLOR_VARIANTS } from "consts"
import { TYPES } from "./consts"
import s from "./ctaButton.module.scss"

export default function CtaButton(props) {
  const intl = useIntl()
  const [pipedriveVisible, setPipedriveVisible] = useState(false)

  function handlePipedriveClick(event) {
    event.preventDefault()
    setPipedriveVisible(true)
    pushGTMEvent(props.click_gtm_event)

    if (props.toggleBackdropFilter) {
      props.toggleBackdropFilter()
    }
  }

  function handlePipedriveBackdropClose() {
    setPipedriveVisible(false)

    if (props.toggleBackdropFilter) {
      props.toggleBackdropFilter()
    }
  }

  switch (props.type) {
    case TYPES.PIPEDRIVE_POP_UP:
      return (
        <>
          {typeof window === "object" &&
            createPortal(
              <Backdrop
                open={pipedriveVisible}
                onBackdropClose={handlePipedriveBackdropClose}
              >
                <div
                  className={cns("pipedriveWebForms", s.pipedriveWrapper)}
                  data-pd-webforms={props.link}
                >
                  <IconButton
                    aria-label="close"
                    className={s.backdropCloseButton}
                    onClick={handlePipedriveBackdropClose}
                  >
                    <Icon>close</Icon>
                  </IconButton>
                  <iframe
                    data-cookieconsent="statistics"
                    data-cookieblock-src={`${props.link}?embeded=1`}
                    title={props.text}
                    className={cns(
                      s.pipedriveIframe,
                      "cookieconsent-optin-statistics"
                    )}
                  />
                  <div
                    className={cns(
                      s.pipedriveConsent,
                      "cookieconsent-optout-statistics"
                    )}
                  >
                    <p className={s.pipedriveConsentText}>
                      {intl.formatMessage({
                        id:
                          "Please accept statistics cookies to view this content.",
                      })}
                    </p>
                  </div>
                </div>
              </Backdrop>,
              document.body
            )}
          <Button
            {...props}
            onClick={handlePipedriveClick}
            variant={props.variant || "primary"}
            color={props.color ? props.color : COLOR_VARIANTS.DARK}
          />
        </>
      )
    case TYPES.ASSET:
      return props.asset && <Button {...props} link={props.asset.filename} />
    default:
      return <Button {...props} />
  }
}
