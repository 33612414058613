/**
 * Ensure slugs start with forward slash and remove any potential double forward slashes
 *
 * @param {String} slug
 *
 * @returns {String | undefined}
 */
function handleLeadingSlash(slug) {
  if (!slug) return
  const slugStartingWithForwardSlash = slug.startsWith("/") ? slug : "/" + slug
  return slugStartingWithForwardSlash.replace(/\/+/g, "/")
}

export default handleLeadingSlash
