import React from "react"
import Image from "./Image"
import cns from "classnames"

import s from "./imageSelector.module.scss"

const ImageSelector = ({
  image_mobile,
  image_tablet,
  image_desktop,
  ...props
}) => [
  <Image
    {...props}
    image={image_mobile}
    className={cns(s.imageMobile, props.className)}
  />,
  <Image
    {...props}
    image={image_tablet}
    className={cns(s.imageTablet, props.className)}
  />,
  <Image
    {...props}
    image={image_desktop}
    className={cns(s.imageDesktop, props.className)}
  />,
]

export default ImageSelector
