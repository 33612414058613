import React from "react"
import s from "./socialTestimonials.module.scss"
import selectColorVariantClassName from "helpers/selectColorVariantClassName"

const TestimonialItem = ({ blok, variant }) => {
  const selectClassName = selectColorVariantClassName(variant)

  const textClassName = selectClassName(
    s.testimonialText,
    s.testimonialTextLight
  )

  const authorClassName = selectClassName(
    s.testimonialAuthor,
    s.testimonialAuthorLight
  )

  return (
    <div className={s.testimonialItem}>
      <p className={textClassName}>{blok.testimonial_text}</p>
      <div className={authorClassName}>
        <span className={s.testimonialAuthorName}>
          {blok.testimonial_user_name}
        </span>
        <span className={s.testimonialAuthorPositionCompany}>
          {blok.testimonial_user_position_company}
        </span>
      </div>
    </div>
  )
}

export default TestimonialItem
