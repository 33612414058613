import React from "react"
import SbEditable from "storyblok-react"
import Markdown from "components/_common/Markdown"
import isValidArray from "helpers/isValidArray"
import s from "./columns.module.scss"

export default function Columns({ columns }) {
  if (!isValidArray(columns)) {
    return null
  }

  return columns.map(column => {
    const { _uid, value } = column

    return (
      <SbEditable key={_uid} content={column}>
        <td className={s.tableData}>
          <Markdown>{value}</Markdown>
        </td>
      </SbEditable>
    )
  })
}
