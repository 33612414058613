import React from "react"
import cns from "classnames"
import Column from "./Column"
import Wrapper from "components/_common/Wrapper/Wrapper"
import withSbEditable from "components/_bloks/withSbEditable"
import s from "./flexibleTextColumns.module.scss"
import selectColorVariantClassName from "helpers/selectColorVariantClassName"
import { COLOR_VARIANTS } from "consts"

const FlexibleTextColumns = ({ columns, color_variant }) => {
  const selectClassName = selectColorVariantClassName(
    color_variant,
    COLOR_VARIANTS.LIGHT_GRAY
  )

  const containerClassName = selectClassName(
    s.containerDark,
    s.containerLight,
    s.containerLightGray
  )

  return (
    <Wrapper className={containerClassName}>
      {columns.map(column => (
        <Column {...column} key={column._uid} />
      ))}
    </Wrapper>
  )
}

export default withSbEditable(FlexibleTextColumns)
