import React from "react"
import cns from "classnames"
import withSbEditable from "components/_bloks/withSbEditable"
import Wrapper from "components/_common/Wrapper"
import isValidArray from "helpers/isValidArray"
import s from "./logoBlok.module.scss"

// Empty element to force flexbox to wrap to a new line
const LineBreak = ({ elementNumber }) =>
  elementNumber % 2 === 0 ? <div className={s.logoLineBreak}></div> : null

const LogoBlok = ({
  logos,
  highlight,
  background,
  padding_top,
  padding_bottom,
}) => {
  if (!isValidArray(logos)) {
    return null
  }

  return (
    <section className={s.logoBlokSection} style={{ background }}>
      <Wrapper
        paddingTop={padding_top}
        paddingBottom={padding_bottom}
        className={cns(s.logoBlok, {
          [s.logoBlockPaddings]: !padding_bottom && !padding_top,
        })}
        style={{ background }}
      >
        <div className={s.logoBlokGrid}>
          {logos.map((logo, i) => (
            <>
              <img
                key={logo._uid}
                src={logo.image}
                alt={logo.accessibility_text}
                className={s.logoBlockImage}
              />
              <LineBreak elementNumber={i + 1} />
            </>
          ))}
        </div>
        {highlight && <h4 className={s.logoBlokHighlight}>{highlight}</h4>}
      </Wrapper>
    </section>
  )
}

export default withSbEditable(LogoBlok)
