import React from "react"
import CtaButton from "components/_common/CtaButton"
import withSbEditable from "components/_bloks/withSbEditable"
import s from "./blogCta.module.scss"

const BlogCtaColumn = ({
  description,
  cta_name,
  cta_link_v2,
  link_type_v2,
}) => {
  return (
    <div className={s.column}>
      <p>{description}</p>
      {cta_name && cta_link_v2?.cached_url && (
        <CtaButton
          className={s.ctaButton}
          text={cta_name}
          link={cta_link_v2.cached_url}
          type={link_type_v2}
        />
      )}
    </div>
  )
}

export default withSbEditable(BlogCtaColumn)
