import React from "react"
import isValidArray from "helpers/isValidArray"
import BackCta from "components/_common/BackCta"
import CtaButton from "components/_common/CtaButton"
import Wrapper from "components/_common/Wrapper"
import Img from "components/_common/Image"
import withSbEditable from "components/_bloks/withSbEditable"
import s from "./productHeader.module.scss"

const ProductHeader = ({
  image,
  back_button_cta,
  back_button_link,
  cta_buttons,
  heading,
  subheading,
  content,
  padding_top,
  padding_top_mb,
  padding_bottom,
  padding_bottom_mb,
}) => {
  return (
    <Wrapper
      paddingTop={padding_top}
      paddingTopMb={padding_top_mb}
      paddingBottom={padding_bottom}
      paddingBottomMb={padding_bottom_mb}
    >
      {back_button_cta && back_button_link?.cached_url && (
        <BackCta to={back_button_link.cached_url} text={back_button_cta} />
      )}
      <div className={s.content}>
        <div>
          <Img
            src={image}
            options={{
              maxWidth: 300,
            }}
            fluid
          />
        </div>
        <div>
          <h1 className={s.heading}>{heading}</h1>
          <h3 className={s.subheading}>{subheading}</h3>
          <p className={s.description}>{content}</p>
          <div className="cta-container">
            {isValidArray(cta_buttons) &&
              cta_buttons.map(cta_button_simple => (
                <CtaButton
                  key={cta_button_simple._uid}
                  text={cta_button_simple.text}
                  link={
                    cta_button_simple.url.url ||
                    cta_button_simple.url.cached_url
                  }
                  variant={cta_button_simple.color_variant}
                />
              ))}
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default withSbEditable(ProductHeader)
