import React from "react"
import withSbEditable from "components/_bloks/withSbEditable"
import SectionHeading from "components/_common/SectionHeading"
import Wrapper from "components/_common/Wrapper"
import isValidArray from "helpers/isValidArray"
import PartnersLogo from "./PartnersLogo"
import s from "./partners.module.scss"

const Partners = ({
  heading,
  logos,
  padding_top,
  padding_top_mb,
  padding_bottom,
  padding_bottom_mb,
}) => {
  if (!isValidArray(logos)) {
    return null
  }

  return (
    <section className={s.bgColor}>
      <Wrapper
        paddingTop={padding_top}
        paddingTopMb={padding_top_mb}
        paddingBottom={padding_bottom}
        paddingBottomMb={padding_bottom_mb}
        renderAs="div"
      >
        <SectionHeading text={heading} />
        <div className={s.inner}>
          {logos.map(partners_logo => (
            <PartnersLogo key={partners_logo._uid} blok={partners_logo} />
          ))}
        </div>
      </Wrapper>
    </section>
  )
}

export default withSbEditable(Partners)
