import React, { useState } from "react"
import cns from "classnames"
import SectionHeading from "components/_common/SectionHeading"
import CtaButton from "components/_common/CtaButton"
import Wrapper from "components/_common/Wrapper"
import Img from "components/_common/Image"
import withSbEditable from "components/_bloks/withSbEditable"
import isValidArray from "helpers/isValidArray"
import { TEXT_VARIANTS } from "consts"
import s from "./contactcta.module.scss"

import flagFR from "images/flags/fr.png"
import flagEN from "images/flags/en.png"
import flagDE from "images/flags/de.png"
import flagPT from "images/flags/pt.svg"
import flagES from "images/flags/es.svg"
import flagCH from "images/flags/ch.svg"
import flagIT from "images/flags/it.svg"

const flagIcon = {
  default: flagEN,
  de: flagDE,
  fr: flagFR,
  pt: flagPT,
  es: flagES,
  zh: flagCH,
  it: flagIT,
}

const ContactCta = ({
  heading,
  section_bg_image,
  sales_contact_image,
  title,
  description,
  flags,
  cta_description,
  cta_text,
  cta_link_v2,
  cta_link_type_v2,
  padding_top,
  padding_top_mb,
  padding_bottom,
  padding_bottom_mb,
}) => {
  const [hasBackdropFilter, setHasBackdropFilter] = useState(() => true)
  const ctaContentClass = cns(
    s.ctaContent,
    hasBackdropFilter ? s.ctaContentBackdrop : null
  )

  // backdrop-filter css style causes Pipedrive popup to be fixed to container not viewport
  function toggleBackdropFilter() {
    setHasBackdropFilter(prevState => !prevState)
  }

  return (
    <Wrapper
      className={s.sectionBg}
      backgroundImage={section_bg_image.filename}
      paddingTop={padding_top}
      paddingTopMb={padding_top_mb}
      paddingBottom={padding_bottom}
      paddingBottomMb={padding_bottom_mb}
    >
      <SectionHeading
        className={s.sectionHeading}
        variant={TEXT_VARIANTS.WHITE}
        text={heading}
      />
      <div className={ctaContentClass}>
        <div className={s.supportInfoWrapper}>
          <Img
            src={sales_contact_image.filename}
            className={s.contactImage}
            options={{
              width: 152,
            }}
            alt="salesContact"
            fixed
          />
          <div>
            <div className={s.supportTitleWrapper}>
              <h4 className={s.supportTitle}>{title}</h4>
              {isValidArray(flags) && (
                <div className={s.flagItemsWrapper}>
                  {flags.map((flag, index) => (
                    <img
                      key={`${flag}-${index}`}
                      width="16"
                      height="16"
                      alt={`flag_${flag}`}
                      src={flagIcon[flag]}
                      className={s.flagItem}
                    />
                  ))}
                </div>
              )}
            </div>
            <p className={s.supportDesc}>{description}</p>
          </div>
        </div>
        <div className={s.ctaInfoWrapper}>
          <div className={s.ctaInfoWrapperInner}>
            <p>{cta_description}</p>
            {cta_text && cta_link_v2?.cached_url && (
              <CtaButton
                text={cta_text}
                link={cta_link_v2.cached_url}
                type={cta_link_type_v2}
                toggleBackdropFilter={toggleBackdropFilter}
              />
            )}
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default withSbEditable(ContactCta)
