import React from "react"
import loadable from "@loadable/component"
import withSbEditable from "components/_bloks/withSbEditable"
import SectionHeading from "components/_common/SectionHeading"
import Wrapper from "components/_common/Wrapper"
import isValidArray from "helpers/isValidArray"
import { TEXT_VARIANTS } from "consts"
import s from "./includedServices.module.scss"

const LazySwiper = loadable(() => import("./Swiper"))

const IncludedServices = ({
  heading,
  section_bg,
  services,
  padding_top,
  padding_top_mb,
  padding_bottom,
  padding_bottom_mb,
}) => {
  if (!isValidArray(services)) {
    return null
  }

  return (
    <Wrapper
      className={s.sectionBg}
      backgroundImage={section_bg.filename}
      paddingTop={padding_top}
      paddingTopMb={padding_top_mb}
      paddingBottom={padding_bottom}
      paddingBottomMb={padding_bottom_mb}
    >
      <div className={s.servicesWrapper}>
        <SectionHeading
          className={s.sectionHeading}
          variant={TEXT_VARIANTS.WHITE}
          text={heading}
        />
        <LazySwiper
          services={services}
          fallback={<div style={{ height: "255px" }} />}
        />
      </div>
    </Wrapper>
  )
}

export default withSbEditable(IncludedServices)
