import { LANGUAGES } from "consts"

export function isDefault(lang) {
  return lang === LANGUAGES.DEFAULT
}

export function isStoryblokDefault(lang) {
  return lang === LANGUAGES.STORYBLOK_DEFAULT
}

export function isChinese(lang) {
  return lang === LANGUAGES.CHINESE
}

export function isStoryblokChinese(lang) {
  return lang === LANGUAGES.STORYBLOK_CHINESE
}

// TODO: Remove after ChangeLanguage refactor
export function getStoryblokLocale(currentLang) {
  return isDefault(currentLang)
    ? LANGUAGES.STORYBLOK_DEFAULT
    : isChinese(currentLang)
    ? LANGUAGES.STORYBLOK_CHINESE
    : currentLang
}

export default {
  isDefault,
  isStoryblokDefault,
  isChinese,
  isStoryblokChinese,
  getStoryblokLocale,
}
