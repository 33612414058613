import React from "react"
import withSbEditable from "components/_bloks/withSbEditable"
import renderBloks from "helpers/renderBloks"
import Components from "./components"

const ContainerColumn = ({ body }) => {
  return <div>{renderBloks(body, Components)}</div>
}

export default withSbEditable(ContainerColumn)
