import cns from "classnames"
import { COLOR_VARIANTS } from "consts"

export default function selectColorVariantClassName(
  variant,
  defaultVariant = COLOR_VARIANTS.DARK
) {
  const selectVariant = compareToVariant =>
    (!variant && compareToVariant === defaultVariant) ||
    variant === compareToVariant

  return (dark, light, lightGray) =>
    cns({
      [dark]: selectVariant(COLOR_VARIANTS.DARK),
      [light]: selectVariant(COLOR_VARIANTS.LIGHT),
      [lightGray]: selectVariant(COLOR_VARIANTS.LIGHT_GRAY),
    })
}
