export function truncateString(string, maxLength = 100) {
  if (typeof string !== "string") {
    return ""
  }

  if (string.length <= maxLength) {
    return string
  }
  return string.slice(0, maxLength) + "..."
}
