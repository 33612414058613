import React from "react"
import CtaButton from "components/_common/CtaButton"
import Img from "components/_common/Image"
import withSbEditable from "components/_bloks/withSbEditable"
import s from "./routing.module.scss"

const RoutingColumn = ({
  image,
  heading,
  description,
  cta_name,
  cta_link,
  cta_type,
}) => {
  return (
    <div className={s.column}>
      <div className={s.imageWrapper}>
        <div className={s.imageInner}>
          <Img
            src={image}
            options={{
              width: 140,
            }}
            fixed
          />
        </div>
      </div>
      <div className={s.content}>
        <h4 className={s.columnHeading}>{heading}</h4>
        <span className={s.columnDescription}>{description}</span>
      </div>
      <div className={s.cta}>
        <CtaButton
          text={cta_name}
          link={cta_link?.cached_url}
          type={cta_type}
        />
      </div>
    </div>
  )
}

export default withSbEditable(RoutingColumn)
