import React from "react"
import SbEditable from "storyblok-react"
import cns from "classnames"
import withSbEditable from "components/_bloks/withSbEditable"
import Renderer from "components/_common/Renderer"
import CtaButton from "components/_common/CtaButton"
import { getCtaButtonProps } from "components/_common/CtaButton/helpers"
import Img from "components/_common/Image"
import { isValidArray, isValidNumber } from "helpers"
import { TEXT_VARIANTS } from "consts"
import { BACKGROUND_POSITIONS } from "./consts"
import s from "./imageText.module.scss"
import Heading from "components/_common/Heading"

const ImageTextColumn = ({
  heading_text,
  heading_text_variant,
  content_text,
  content_text_variant,
  image_reference,
  image_alt,
  image_title,
  cta_buttons,
  background_image,
  additionalProps,
  heading_line,
  heading_line_position,
  background_color,
}) => {
  const {
    width,
    index,
    paddingTopMobile,
    paddingBottomMobile,
  } = additionalProps

  const hasImage = !!image_reference.filename
  const hasBackgroundImage = !!background_image.filename

  return (
    <div
      style={{
        "--padding-top-mobile":
          index === 1 &&
          isValidNumber(paddingTopMobile) &&
          `${paddingTopMobile}px`,
        "--padding-bottom-mobile":
          index === 0 &&
          isValidNumber(paddingBottomMobile) &&
          `${paddingBottomMobile}px`,
        background: background_color,
      }}
      className={cns(s.container, hasImage && s.centered)}
    >
      {hasImage ? (
        <ColumnWithImage {...{ image_reference, image_alt, image_title }} />
      ) : (
        <ColumnWithText
          {...{
            heading_text,
            heading_text_variant,
            content_text,
            content_text_variant,
            cta_buttons,
            heading_line,
            heading_line_position,
          }}
        />
      )}
      {hasBackgroundImage && (
        <ColumnBackground
          background_image={background_image}
          width={width}
          position={
            index === 0 ? BACKGROUND_POSITIONS.LEFT : BACKGROUND_POSITIONS.RIGHT
          }
        />
      )}
    </div>
  )
}

function ColumnWithImage({ image_reference, image_alt, image_title }) {
  return (
    <Img
      src={image_reference.filename}
      options={{
        maxWidth: 447,
      }}
      className={s.image}
      alt={image_alt}
      title={image_title}
      fluid
    />
  )
}

function ColumnWithText({
  heading_text,
  heading_text_variant,
  content_text,
  content_text_variant,
  cta_buttons,
  heading_line,
  heading_line_position,
}) {
  return (
    <>
      {heading_text && (
        <Heading
          className={cns(s.heading, {
            [s.headingWhite]: heading_text_variant === TEXT_VARIANTS.WHITE,
            [s.headingBlack]: heading_text_variant === TEXT_VARIANTS.BLACK,
          })}
          headingLine={heading_line}
          linePosition={heading_line_position}
        >
          {heading_text}
        </Heading>
      )}
      {content_text && (
        <Renderer
          className={cns(s.content, {
            [s.contentWhite]: content_text_variant === TEXT_VARIANTS.WHITE,
            [s.contentBlack]: content_text_variant === TEXT_VARIANTS.BLACK,
            [s.contentGrey]: content_text_variant === TEXT_VARIANTS.GREY100,
          })}
          content={content_text}
        />
      )}
      {isValidArray(cta_buttons) && (
        <div className={s.ctaContainer}>
          {cta_buttons.map(ctaButton => (
            <SbEditable key={ctaButton._uid} content={ctaButton}>
              <CtaButton {...getCtaButtonProps(ctaButton)} />
            </SbEditable>
          ))}
        </div>
      )}
    </>
  )
}

function ColumnBackground({ background_image, width, position }) {
  return (
    <Img
      src={background_image.filename}
      options={{
        maxWidth: 447,
      }}
      className={cns(s.columnBackground, {
        [s.leftColumnBackground]: position === BACKGROUND_POSITIONS.LEFT,
        [s.rightColumnBackground]: position === BACKGROUND_POSITIONS.RIGHT,
      })}
      style={{
        position: "absolute",
        "--column-width": width,
      }}
      fluid
    />
  )
}

export default withSbEditable(ImageTextColumn)
