import React, { useState, useEffect } from "react"
import cns from "classnames"
import { useBanner } from "./Banner"
import Navigation from "./Navigation"
import TopBar from "./TopBar"
import s from "./topBar.module.scss"

export default function TopBarWrapper({
  path,
  banner,
  blok,
  location,
  isPaginating,
  langLinks,
  variant,
  version,
}) {
  const [scrollTop, setScrollTop] = useState(0)
  const [navigationOpen, setNavigationOpen] = useState(false)
  const [isBannerVisible, closeBanner] = useBanner(banner, path, isPaginating)

  const longerBannerText = isBannerVisible && banner.text.length >= 60

  useEffect(() => {
    const handleScroll = () => {
      setScrollTop(window.scrollY)
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  function toggleNavigationOpen() {
    setNavigationOpen(!navigationOpen)
  }

  const isNavigationDynamic = blok.version === "dynamic"
  const scrolled = scrollTop > 60

  return (
    <header
      className={cns({
        [s.topBarWrapper]: true,
        [s.topBarWrapperLight]: blok.variant === "light",
        [s.topBarIndent]: !isBannerVisible && !isNavigationDynamic,
        [s.topBarScrolledLight]:
          isNavigationDynamic && scrolled && blok.variant === "light",
        [s.topBarScrolledDark]:
          isNavigationDynamic && scrolled && blok.variant === "dark",
        [s.topBarAndBannerIndent]: isBannerVisible,
        [s.topBarDynamic]: blok.version === "dynamic",
        [s.topBarAndBannerIndentBiggerMobile]:
          isBannerVisible && longerBannerText,
      })}
    >
      <TopBar
        {...{
          location,
          blok,
          banner,
          isBannerVisible,
          closeBanner,
          langLinks,
          variant,
          version,
          scrolled,
        }}
        onMenuButtonClick={toggleNavigationOpen}
      />
      <Navigation
        blok={blok}
        onMenuButtonClick={toggleNavigationOpen}
        isOpen={navigationOpen}
      />
      {/* Temporarily disabled component as it uses gatsby-plugin-smoothscroll */}
      {/* which causes problem with blurry website when used with Cookiebot */}
      {/* <BackToTopButton {...{ navigationOpen }} /> */}
    </header>
  )
}
