import React from "react"
import Wrapper from "components/_common/Wrapper"
import withSbEditable from "components/_bloks/withSbEditable"
import renderBloks from "helpers/renderBloks"

const Section = ({
  body,
  padding_top,
  padding_top_mb,
  padding_bottom,
  padding_bottom_mb,
  background_image,
}) => {
  return (
    <Wrapper
      paddingTop={padding_top}
      paddingTopMb={padding_top_mb}
      paddingBottom={padding_bottom}
      paddingBottomMb={padding_bottom_mb}
      backgroundImage={background_image?.filename}
    >
      {renderBloks(body)}
    </Wrapper>
  )
}

export default withSbEditable(Section)
