import React from "react"
import withSbEditable from "components/_bloks/withSbEditable"
import Button from "components/_common/Button"
import s from "./neverHeardUsCta.module.scss"

import iconArrowRight from "images/right-arrow.svg"

const NeverHeardUsCtaColumn = ({
  heading,
  description,
  cta_url,
  cta_text,
  link_url,
  link_text,
}) => {
  return (
    <div className={s.content}>
      <h3>{heading}</h3>
      {description && <p>{description}</p>}
      {cta_url?.cached_url && cta_text && (
        <div className={s.ctaButtonWrapper}>
          <Button text={cta_text} link={cta_url.cached_url} />
        </div>
      )}
      {link_url?.cached_url && link_text && (
        <div className={s.linkWrapper}>
          <Button
            text={link_text}
            className={s.link}
            link={link_url.cached_url}
            asLink={true}
          />
          <i>
            <img width="10" height="10" src={iconArrowRight} alt="arrow" />
          </i>
        </div>
      )}
    </div>
  )
}

export default withSbEditable(NeverHeardUsCtaColumn)
