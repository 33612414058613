import React from "react"
import cns from "classnames"
import withSbEditable from "components/_bloks/withSbEditable"
import Link from "components/_common/Link"
import Img from "components/_common/Image"
import s from "./imageGroup.module.scss"

const ImageGroupItem = ({
  image,
  title,
  alt,
  link_to,
  link_new_tab,
  width,
  additionalProps,
}) => {
  const { height, isOnlyImage } = additionalProps

  const hasLink = !!link_to?.cached_url

  const imageComponent = (
    <div
      className={cns(!hasLink && s.fullWidth)}
      style={{
        height: height && `${height}px`,
        width: isOnlyImage && !hasLink && (width ? `${width}%` : "100%"),
      }}
    >
      <Img
        src={image.filename}
        className={s.image}
        title={title}
        alt={alt}
        fluid
      />
    </div>
  )

  return hasLink ? (
    <Link
      style={{
        width: isOnlyImage && (width ? `${width}%` : "100%"),
      }}
      className={cns(!isOnlyImage && s.fullWidth)}
      to={link_to.cached_url}
      newTab={link_new_tab}
    >
      {imageComponent}
    </Link>
  ) : (
    imageComponent
  )
}

export default withSbEditable(ImageGroupItem)
