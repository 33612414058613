import React from "react"
import isValidArray from "helpers/isValidArray"
import Wrapper from "components/_common/Wrapper"
import withSbEditable from "components/_bloks/withSbEditable"
import SolutionsColumn from "./SolutionsColumn"
import s from "./solutions.module.scss"

const Solutions = ({
  heading,
  subheading,
  columns,
  padding_top,
  padding_top_mb,
  padding_bottom,
  padding_bottom_mb,
  background_color,
}) => {
  if (!isValidArray(columns)) {
    return null
  }

  return (
    <Wrapper
      paddingTop={padding_top}
      paddingTopMb={padding_top_mb}
      paddingBottom={padding_bottom}
      paddingBottomMb={padding_bottom_mb}
      className={s.wrapper}
      style={{ backgroundColor: background_color }}
    >
      <div className={s.content}>
        <h2 className={s.heading}>{heading}</h2>
        {subheading ? <h3 className={s.subheading}>{subheading}</h3> : null}
        <div className={s.columns}>
          {columns.map(solutions_column => {
            return (
              <SolutionsColumn
                key={solutions_column._uid}
                blok={solutions_column}
              />
            )
          })}
        </div>
      </div>
    </Wrapper>
  )
}

export default withSbEditable(Solutions)
