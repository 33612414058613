import React from "react"
import withSbEditable from "components/_bloks/withSbEditable"
import Wrapper from "components/_common/Wrapper"
import isValidArray from "helpers/isValidArray"
import NeverHeardUsCtaColumn from "./NeverHeardUsCtaColumn"
import s from "./neverHeardUsCta.module.scss"

const NeverHeardUsCta = ({
  columns,
  padding_top,
  padding_top_mb,
  padding_bottom,
  padding_bottom_mb,
}) => {
  if (!isValidArray(columns)) {
    return null
  }

  return (
    <Wrapper
      paddingTop={padding_top}
      paddingTopMb={padding_top_mb}
      paddingBottom={padding_bottom}
      paddingBottomMb={padding_bottom_mb}
      className={s.wrapper}
      fullWidth
    >
      <div className={s.imagesBgWrapper}>
        {columns.map(never_heard_us_cta_column => (
          // TODO: Replace background images with optimized Gatsby Image
          <div
            key={never_heard_us_cta_column._uid}
            className={s.imageBg}
            style={{
              backgroundImage: `url(${never_heard_us_cta_column.bg_image.filename})`,
            }}
          />
        ))}
      </div>
      <div className="container">
        <div className={s.columns}>
          {columns.map(never_heard_us_cta_column => {
            return (
              <NeverHeardUsCtaColumn
                key={never_heard_us_cta_column._uid}
                blok={never_heard_us_cta_column}
              />
            )
          })}
        </div>
      </div>
    </Wrapper>
  )
}

export default withSbEditable(NeverHeardUsCta)
