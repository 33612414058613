import React from "react"
import Renderer from "components/_common/Renderer"
import withSbEditable from "components/_bloks/withSbEditable"
import s from "./solutions.module.scss"

const SolutionsColumn = ({
  image,
  image_alignment,
  heading,
  heading_alignment,
  content,
}) => {
  return (
    <div className={s.columnItem}>
      <div
        className={s.columnImageContainer}
        style={{ justifyContent: image_alignment }}
      >
        <img src={image} alt="" className={s.columnImage} />
      </div>
      <h3
        className={s.columnHeading}
        style={{ textAlign: heading_alignment ?? "left" }}
      >
        {heading}
      </h3>
      {content && (
        <div className={s.contentWrapper}>
          <Renderer content={content} className={s.details} />
        </div>
      )}
    </div>
  )
}

export default withSbEditable(SolutionsColumn)
