import React from "react"
import isValidArray from "helpers/isValidArray"
import Wrapper from "components/_common/Wrapper"
import Img from "components/_common/Image"
import withSbEditable from "components/_bloks/withSbEditable"
import RichQuote from "./RichQuote"
import s from "./quotes.module.scss"

const Quotes = ({
  quotes,
  background_image,
  padding_top,
  padding_top_mb,
  padding_bottom,
  padding_bottom_mb,
}) => {
  if (!isValidArray(quotes)) {
    return null
  }

  return (
    <section className={s.section}>
      <Img
        src={background_image.filename}
        options={{ maxWidth: 1440 }}
        className={s.sectionBg}
        style={{
          position: "absolute",
        }}
        fluid
      />
      <Wrapper
        renderAs="div"
        className={s.wrapper}
        paddingTop={padding_top}
        paddingTopMb={padding_top_mb}
        paddingBottom={padding_bottom}
        paddingBottomMb={padding_bottom_mb}
      >
        {quotes.map(rich_quote => (
          <RichQuote key={rich_quote._uid} blok={rich_quote} />
        ))}
      </Wrapper>
    </section>
  )
}

export default withSbEditable(Quotes)
