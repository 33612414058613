import React from "react"
import isValidArray from "helpers/isValidArray"
import CtaButton from "components/_common/CtaButton"
import { getCtaButtonProps } from "components/_common/CtaButton/helpers"
import Wrapper from "components/_common/Wrapper"
import Renderer from "components/_common/Renderer"
import Img from "components/_common/Image"
import withSbEditable from "components/_bloks/withSbEditable"
import s from "./productCta.module.scss"

const ProductCta = ({
  image,
  heading,
  content,
  cta_buttons,
  padding_top,
  padding_top_mb,
  padding_bottom,
  padding_bottom_mb,
}) => {
  if (!image) {
    return null
  }

  return (
    <Wrapper
      paddingTop={padding_top}
      paddingTopMb={padding_top_mb}
      paddingBottom={padding_bottom}
      paddingBottomMb={padding_bottom_mb}
    >
      <div className={s.container}>
        <div className={s.content}>
          <h2 className={s.heading}>{heading}</h2>
          <Renderer className={s.content} content={content} />
          <div className="cta-container">
            {isValidArray(cta_buttons) &&
              cta_buttons.map(cta_button => (
                <CtaButton
                  key={cta_button._uid}
                  {...getCtaButtonProps(cta_button)}
                />
              ))}
          </div>
        </div>
        {image && (
          <div className={s.imageContainer}>
            <Img
              src={image}
              options={{
                maxWidth: 447,
              }}
              fluid
            />
          </div>
        )}
      </div>
    </Wrapper>
  )
}

export default withSbEditable(ProductCta)
