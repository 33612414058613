export default function addNotification(title, type, domRef) {
  domRef.current.addNotification({
    message: title,
    type: type,
    insert: "top",
    container: "top-right",
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"],
    dismiss: { duration: 5000 },
    dismissable: { click: true },
  })
}
