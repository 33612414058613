import React from "react"
import isValidArray from "helpers/isValidArray"
import Wrapper from "components/_common/Wrapper"
import withSbEditable from "components/_bloks/withSbEditable"
import SellerFeaturesColumn from "./SellerFeaturesColumn"
import s from "./sellerFeatures.module.scss"

const SellerFeatures = ({
  columns,
  padding_top,
  padding_top_mb,
  padding_bottom,
  padding_bottom_mb,
}) => {
  if (!isValidArray(columns)) {
    return null
  }

  return (
    <Wrapper
      paddingTop={padding_top}
      paddingTopMb={padding_top_mb}
      paddingBottom={padding_bottom}
      paddingBottomMb={padding_bottom_mb}
      className={s.wrapper}
    >
      <div className={s.featuresBox}>
        <div className={s.columns}>
          {columns.map(seller_features_column => {
            return (
              <SellerFeaturesColumn
                key={seller_features_column._uid}
                blok={seller_features_column}
              />
            )
          })}
        </div>
      </div>
    </Wrapper>
  )
}

export default withSbEditable(SellerFeatures)
