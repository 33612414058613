import React from "react"
import SectionHeading from "components/_common/SectionHeading"
import CtaButton from "components/_common/CtaButton"
import Wrapper from "components/_common/Wrapper"
import withSbEditable from "components/_bloks/withSbEditable"
import isValidArray from "helpers/isValidArray"
import VideoShowcaseItem from "./VideoShowcaseItem"
import s from "./videoShowcase.module.scss"

const VideoShowcase = ({
  heading,
  videos,
  cta_text,
  cta_link,
  cta_type,
  padding_top,
  padding_top_mb,
  padding_bottom,
  padding_bottom_mb,
}) => {
  if (!isValidArray(videos)) {
    return null
  }

  return (
    <Wrapper
      paddingTop={padding_top}
      paddingTopMb={padding_top_mb}
      paddingBottom={padding_bottom}
      paddingBottomMb={padding_bottom_mb}
      className={s.wrapper}
      fullWidth
    >
      <div className={s.videoContainer}>
        <SectionHeading text={heading} />
        <div className={s.row}>
          <div className={s.columns}>
            {videos.map(video_showcase_item => (
              <VideoShowcaseItem
                key={video_showcase_item._uid}
                blok={video_showcase_item}
              />
            ))}
          </div>
        </div>

        {cta_text && cta_link?.cached_url && (
          <div className={s.ctaWrapper}>
            <CtaButton
              text={cta_text}
              link={cta_link.cached_url}
              type={cta_type}
            />
          </div>
        )}
      </div>
    </Wrapper>
  )
}

export default withSbEditable(VideoShowcase)
