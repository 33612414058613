import useStoryblokImage, { IMAGE_TYPES } from "hooks/useStoryblokImage"
import GatsbyImage from "gatsby-image"
import React from "react"

const Image = ({ image, className, style }) => {
  const fluidProps = useStoryblokImage(image, IMAGE_TYPES.FLUID, {
    maxWidth: 1440,
  })

  return (
    <GatsbyImage
      fluid={fluidProps}
      style={style}
      loading="eager"
      fadeIn={false}
      className={className}
    />
  )
}

export default Image
