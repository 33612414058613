export function getColumnWidthValue(column_width) {
  if (column_width <= 25) {
    return 25
  }

  if (column_width >= 75) {
    return 75
  }

  return column_width
}
