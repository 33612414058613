import HeaderV1 from "./Header/v1"
import HeaderV2 from "./Header/v2"
import Partners from "./Partners"
import Statistics from "./Statistics"
import TradedMaterials from "./TradedMaterials"
import Routing from "./Routing"
import Indices from "./Indices"
import SlideshowHero from "./SlideshowHero"
import LogoBlok from "./LogoBlok"
import PriceCommittee from "./PriceCommittee"
import SocialTestimonials from "./SocialTestimonials"
import Offer from "./Offer"
import ChoosePlan from "./ChoosePlan"
import Solutions from "./Solutions"
import NeverHeardUsCta from "./NeverHeardUsCta"
import SellerFeatures from "./SellerFeatures"
import ContactCta from "./ContactCta"
import Container from "./Container"
import FlexibleTextColumns from "./FlexibleTextColumns"
import Steps from "./Steps"
import ProductSteps from "./ProductSteps"
import Newsletter from "./Newsletter"
import Quote from "./Quote"
import IncludedServices from "./IncludedServices"
import BlogCta from "./BlogCta"
import Quotes from "./Quotes"
import VideoShowcase from "./VideoShowcase"
import LogoShowcase from "./LogoShowcase"
import ProductHeader from "./ProductHeader"
import ProductCta from "./ProductCta"
import ProductDownload from "./ProductDownload"
import RichText from "./RichText"
import Table from "./Table"
import Text from "./Text"
import Image from "./Image"
import TeamMembers from "./TeamMembers"
import ExpansionPanels from "./ExpansionPanels"
import ImageColumnV1 from "./ImageColumn/v1"
import CtaButtonGroup from "./CtaButtonGroup"
import ImageGroup from "./ImageGroup"
import ImageQuotesV1 from "./ImageQuotes/v1"
import ImageTextV1 from "./ImageText/v1"
import ImageTextV1Responsive from "./ImageText/v1-responsive"
import CustomEmbedded from "./CustomEmbedded"
import Section from "./Section"
import JobListings from "./JobListings"
import TestimonialItem from "./SocialTestimonials/TestimonialItem"

export default {
  statistics: Statistics,
  header: HeaderV1,
  header_v2: HeaderV2,
  partners: Partners,
  traded_materials: TradedMaterials,
  routing: Routing,
  indices: Indices,
  price_commitee: PriceCommittee,
  offer: Offer,
  choose_plan: ChoosePlan,
  never_heard_us_cta: NeverHeardUsCta,
  sellers_features: SellerFeatures,
  contact_cta: ContactCta,
  steps: Steps,
  product_steps: ProductSteps,
  newsletter: Newsletter,
  social_testimonials: SocialTestimonials,
  quote: Quote,
  included_services: IncludedServices,
  quotes: Quotes,
  blog_cta: BlogCta,
  video_showcase: VideoShowcase,
  product_header: ProductHeader,
  product_cta: ProductCta,
  product_download: ProductDownload,
  logo_showcase: LogoShowcase,
  logos_blok: LogoBlok,
  container: Container,
  rich_text: RichText,
  table: Table,
  text: Text,
  image: Image,
  team_members: TeamMembers,
  expansion_panels: ExpansionPanels,
  image_column_v1: ImageColumnV1,
  cta_button_group: CtaButtonGroup,
  image_group: ImageGroup,
  image_quotes_v1: ImageQuotesV1,
  image_text_v1: ImageTextV1,
  image_text_v1_responsive: ImageTextV1Responsive,
  custom_embedded: CustomEmbedded,
  slideshow_hero: SlideshowHero,
  section: Section,
  job_listings: JobListings,
  flexible_text_columns: FlexibleTextColumns,
  solutions: Solutions,
}
