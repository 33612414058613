import React, { useState } from "react"
import cns from "classnames"
import Icon from "@material-ui/core/Icon"
import IconButton from "@material-ui/core/IconButton"
import { ITEM_TYPES } from "components/_common/Page/Layout/TopBar/consts"
import CtaButton from "components/_common/CtaButton"
import isValidArray from "helpers/isValidArray"
import { BUTTON_VARIANTS, COLOR_VARIANTS } from "consts"
import SingleItem from "./SingleItem"
import MultipleItem from "./MultipleItem"
import s from "./navigation.module.scss"
import { Helmet } from "react-helmet"

export default function Navigation({ isOpen, onMenuButtonClick, blok }) {
  const {
    items,
    cta_url,
    cta_name,
    secondary_cta_url,
    secondary_cta_name,
    secondary_cta_type,
  } = blok

  const [dropDownOpen, setDropDownOpen] = useState(null)

  function handleRootLinkClick(_uid, link, dropdown_links) {
    if (!dropDownOpen || dropDownOpen._uid !== _uid) {
      setDropDownOpen({ _uid, link, dropdown_links })
    } else {
      setDropDownOpen(null)
    }
  }

  function renderItem(type, props) {
    const rootLink = props.link[0]
    switch (type) {
      case ITEM_TYPES.SINGLE:
        return <SingleItem key={rootLink._uid} link={rootLink} />
      case ITEM_TYPES.MULTIPLE:
        const isOpen = dropDownOpen && dropDownOpen._uid === rootLink._uid
        return (
          <MultipleItem
            key={rootLink._uid}
            {...props}
            isOpen={isOpen}
            onRootLinkClick={handleRootLinkClick}
          />
        )
      default:
        throw new Error("Unknown item type")
    }
  }

  const disabledScroll = isOpen && (
    <Helmet htmlAttributes={{ style: "overflow: hidden;" }} />
  )

  const closeButtonComponent = (
    <IconButton
      className={cns(s.closeButton, !isOpen && s.hidden)}
      aria-label="close"
      onClick={onMenuButtonClick}
    >
      <Icon>close</Icon>
    </IconButton>
  )

  const ctaButtonComponent = (
    <div className={s.ctaButtonWrapper}>
      <CtaButton
        link={cta_url?.cached_url}
        text={cta_name}
        variant={BUTTON_VARIANTS.PRIMARY}
        color={COLOR_VARIANTS.DARK}
        mobileFullWidth={true}
      />
      <CtaButton
        link={secondary_cta_url?.cached_url}
        text={secondary_cta_name}
        type={secondary_cta_type}
        variant={BUTTON_VARIANTS.GHOST}
        color={COLOR_VARIANTS.DARK}
        mobileFullWidth={true}
      />
    </div>
  )

  return (
    <>
      {disabledScroll}
      <div
        className={cns(s.navigationWrapper, isOpen && s.navigationWrapperIn)}
      >
        <div className={s.topContainer}>
          <div
            className={cns({
              [s.logoContainer]: true,
              [s.logoContainerOpen]: isOpen,
            })}
          >
            <img src={blok.logo} alt="Logo" className={s.logo} />
          </div>
          {closeButtonComponent}
        </div>
        <nav className={s.links}>
          {isValidArray(items) && (
            <ul className={s.navigationLinks}>
              {items.map(({ type, ...props }) => renderItem(type, props))}
            </ul>
          )}
          {ctaButtonComponent}
        </nav>
      </div>
    </>
  )
}
