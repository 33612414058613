import React from "react"
import IconButton from "@material-ui/core/IconButton"
import { useIntl } from "gatsby-plugin-intl"
import CtaButton from "components/_common/CtaButton"
import { getStoryblokLocale } from "helpers/locales"
import ChangeLanguage from "./ChangeLanguage"
import s from "./controls.module.scss"
import { BUTTON_VARIANTS } from "consts"

import hamburgerIcon from "images/hamburger.svg"

export default function Controls({
  cta_url,
  cta_name,
  secondary_cta_url,
  secondary_cta_name,
  secondary_cta_type,
  location,
  onMenuButtonClick,
  langLinks,
  variant,
}) {
  const intl = useIntl()

  const ctaButton = (
    <div className={s.ctaButtons}>
      <CtaButton
        link={cta_url?.cached_url}
        text={cta_name}
        variant={BUTTON_VARIANTS.GHOST}
        color={variant}
      />
      <CtaButton
        variant={BUTTON_VARIANTS.PRIMARY}
        text={secondary_cta_name}
        link={secondary_cta_url?.cached_url}
        type={secondary_cta_type}
      />
    </div>
  )

  const languagePicker = (
    <ChangeLanguage
      location={location}
      storyblokLocale={getStoryblokLocale(intl.locale)}
      langLinks={langLinks}
    />
  )

  const menuButton = (
    <IconButton
      className={s.menuButton}
      aria-label="menu"
      onClick={onMenuButtonClick}
    >
      <img src={hamburgerIcon} alt="" />
    </IconButton>
  )

  return (
    <div className={s.controlsWrapper}>
      {languagePicker}
      <div className={s.ctaButtons}>{ctaButton}</div>
      {menuButton}
    </div>
  )
}
