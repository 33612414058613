import React from "react"
import withSbEditable from "components/_bloks/withSbEditable"
import BackCta from "components/_common/BackCta"
import Wrapper from "components/_common/Wrapper"
import isValidArray from "helpers/isValidArray"
import { getColumnWidthValue } from "./helpers"
import ImageTextColumn from "./ImageTextColumn"
import s from "./imageText.module.scss"

const ImageText = ({
  background_image,
  columns,
  column_width,
  back_cta_text,
  back_cta_link,
  padding_top,
  padding_top_mb,
  padding_top_mobile,
  padding_bottom,
  padding_bottom_mb,
  padding_bottom_mobile,
  background_color,
}) => {
  const bothColumnsHaveBackgroundImage = columns.every(
    column => !!column.background_image.filename
  )

  if (!isValidArray(columns)) {
    return null
  }

  return (
    <Wrapper
      backgroundImage={
        !bothColumnsHaveBackgroundImage && background_image.filename
      }
      paddingTop={padding_top}
      paddingTopMb={padding_top_mb}
      paddingBottom={padding_bottom}
      paddingBottomMb={padding_bottom_mb}
      style={{ background: background_color }}
    >
      <ColumnsComponent
        {...{
          columns,
          column_width,
          back_cta_text,
          back_cta_link,
          padding_top_mobile,
          padding_bottom_mobile,
        }}
      />
    </Wrapper>
  )
}

function ColumnsComponent({
  columns,
  column_width,
  back_cta_text,
  back_cta_link,
  padding_top_mobile,
  padding_bottom_mobile,
}) {
  const columnWidth = getColumnWidthValue(column_width)

  return (
    <>
      {back_cta_text && back_cta_link?.cached_url && (
        <BackCta
          to={back_cta_link.cached_url}
          text={back_cta_text}
          className={s.backCta}
        />
      )}
      <div
        className={s.columnsWrapper}
        style={{
          gridTemplateColumns: `${columnWidth}fr ${100 - columnWidth}fr`,
        }}
      >
        {columns.slice(0, 2).map((image_text_v1_column, columnIndex) => (
          <ImageTextColumn
            key={image_text_v1_column._uid}
            blok={image_text_v1_column}
            width={columnIndex === 0 ? columnWidth : 100 - columnWidth}
            index={columnIndex}
            paddingTopMobile={padding_top_mobile}
            paddingBottomMobile={padding_bottom_mobile}
          />
        ))}
      </div>
    </>
  )
}

export default withSbEditable(ImageText)
