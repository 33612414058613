import { useMemo } from "react"
import { DEFAULT_PADDING_VALUES } from "consts"
import { isValidNumber } from "helpers"

const DEFAULT_OPTIONS = { withoutDefaultPaddings: false }

/**
 * @param {object} Object with custom paddings
 * @returns {object} Object containing custom paddings or default paddings which depends on whether custom paddings were specified or not
 */
export default function useDefaultPaddings(
  { paddingTop = 0, paddingTopMb = 0, paddingBottom = 0, paddingBottomMb = 0 },
  { withoutDefaultPaddings } = DEFAULT_OPTIONS
) {
  return useMemo(
    () => ({
      paddingTop: withoutDefaultPaddings
        ? paddingTop
        : (isValidNumber(paddingTop) && paddingTop) ||
          DEFAULT_PADDING_VALUES.PADDING_TOP,
      paddingTopMb: withoutDefaultPaddings
        ? paddingTopMb
        : (isValidNumber(paddingTopMb) && paddingTopMb) ||
          DEFAULT_PADDING_VALUES.PADDING_TOP_MOBILE,
      paddingBottom: withoutDefaultPaddings
        ? paddingBottom
        : (isValidNumber(paddingBottom) && paddingBottom) ||
          DEFAULT_PADDING_VALUES.PADDING_BOTTOM,
      paddingBottomMb: withoutDefaultPaddings
        ? paddingBottomMb
        : (isValidNumber(paddingBottomMb) && paddingBottomMb) ||
          DEFAULT_PADDING_VALUES.PADDING_BOTTOM_MOBILE,
    }),
    [
      paddingTop,
      paddingTopMb,
      paddingBottom,
      paddingBottomMb,
      withoutDefaultPaddings,
    ]
  )
}
