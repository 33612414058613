import React from "react"
import SbEditable from "storyblok-react"

export default function withSbEditable(SbComponent) {
  return ({ blok, ...additionalProps }) => (
    <SbEditable key={blok._uid} content={blok}>
      <SbComponent {...{ ...blok, additionalProps }} />
    </SbEditable>
  )
}
