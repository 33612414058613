import React from "react"
import Wrapper from "components/_common/Wrapper"
import s from "./blogFooter.module.scss"

export default function BlogFooter({ tags }) {
  // field type was converted in CMS in the past, hence this check to prevent errors when its an array
  const areTagsValid = !!(typeof tags === "string" && tags)

  if (!areTagsValid) return null

  return (
    <Wrapper className={s.blogFooter} withoutDefaultPaddings>
      <div className={s.tags}>{tags}</div>
    </Wrapper>
  )
}
