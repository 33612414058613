import { useMemo } from "react"
import { graphql, useStaticQuery } from "gatsby"
import _groupBy from "lodash.groupby"

export default function usePersonioData() {
  const { allPersonioPosition } = useStaticQuery(
    graphql`
      query {
        allPersonioPosition {
          nodes {
            positionId
            name
            recruitingCategory
            schedule
            office {
              name
            }
            department {
              name
            }
          }
        }
      }
    `
  )

  const jobListings = allPersonioPosition.nodes

  const jobListingsByDepartmentName = useMemo(
    () => _groupBy(jobListings, jobListing => jobListing.department.name),
    [jobListings]
  )

  return [jobListingsByDepartmentName, jobListings.length === 0]
}

