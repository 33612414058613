import React from "react"
import AllComponents from "components/_bloks"
import isValidArray from "./isValidArray"

export default function renderBloks(bloks, CustomComponents) {
  const Components =
    typeof CustomComponents === "object" ? CustomComponents : AllComponents

  return (
    isValidArray(bloks) &&
    bloks.map(
      blok =>
        Components[blok.component] &&
        React.createElement(Components[blok.component], {
          key: blok._uid,
          blok,
        })
    )
  )
}
