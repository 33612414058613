import React from "react"
import Wrapper from "components/_common/Wrapper"
import Markdown from "components/_common/Markdown"
import Img from "components/_common/Image"
import withSbEditable from "components/_bloks/withSbEditable"
import s from "./quote.module.scss"

const Quote = ({
  person_avatar,
  person_name,
  person_position,
  person_company,
  quote,
  padding_top,
  padding_top_mb,
  padding_bottom,
  padding_bottom_mb,
  background_color,
}) => {
  return (
    <Wrapper
      className={s.wrapper}
      paddingTop={padding_top}
      paddingTopMb={padding_top_mb}
      paddingBottom={padding_bottom}
      paddingBottomMb={padding_bottom_mb}
      style={{ backgroundColor: background_color }}
    >
      <blockquote className={s.blockquoteBox}>
        <div className={s.citeBox}>
          <Img
            src={person_avatar}
            options={{
              width: 100,
            }}
            className={s.citeAvatar}
            fixed
          />
          <cite>
            <p className={s.citeName}>{person_name}</p>
            <p className={s.citePosition}>{person_position}</p>
            <p className={s.citeCompany}>{person_company}</p>
          </cite>
        </div>

        <div className={s.quote}>
          <Markdown>{quote}</Markdown>
        </div>
      </blockquote>
    </Wrapper>
  )
}

export default withSbEditable(Quote)
