import React from "react"
import Renderer from "components/_common/Renderer"
import Wrapper from "components/_common/Wrapper"
import withSbEditable from "components/_bloks/withSbEditable"

const RichText = ({
  content,
  padding_top,
  padding_top_mb,
  padding_bottom,
  padding_bottom_mb,
  additionalProps,
  background_color,
}) => {
  const { column } = additionalProps

  if (column) {
    return <Renderer content={content} />
  }

  return (
    <Wrapper
      paddingTop={padding_top}
      paddingTopMb={padding_top_mb}
      paddingBottom={padding_bottom}
      paddingBottomMb={padding_bottom_mb}
      style={{ background: background_color }}
    >
      <Renderer content={content} />
    </Wrapper>
  )
}

export default withSbEditable(RichText)
