import React from "react"
import cns from "classnames"
import Img from "components/_common/Image"
import getPaddingProps from "helpers/getPaddingProps"
import useDefaultPaddings from "hooks/useDefaultPaddings"
import s from "./wrapper.module.scss"
import ImageSelector from "components/_bloks/ImageSelector/ImageSelector"

export default function Wrapper({
  fullWidth,
  renderAs = "section",
  backgroundImage,
  responsiveBackgroundImage,
  paddingTop,
  paddingTopMb,
  paddingBottom,
  paddingBottomMb,
  withoutDefaultPaddings,
  className,
  style,
  children: bodyComponentChildren,
  ...rest
}) {
  const paddings = useDefaultPaddings(
    {
      paddingTop,
      paddingTopMb,
      paddingBottom,
      paddingBottomMb,
    },
    { withoutDefaultPaddings }
  )

  const Element = backgroundImage ? "div" : renderAs

  const bodyComponentProps = {
    className: cns({
      container: !fullWidth,
      [s.container]: !fullWidth,
      [s.fullWidthContainer]: fullWidth,
      [s.paddings]: !withoutDefaultPaddings,
      [className]: typeof className === "string",
    }),
    style: {
      ...getPaddingProps(paddings),
      ...style,
    },
    key: "wrapper-body",
    ...rest,
  }

  const contentStyles = cns({
    [s.content]: true,
    [className]: typeof className === "string",
  })

  const bodyComponent = (
    <Element {...bodyComponentProps}>
      <div className={contentStyles}>{bodyComponentChildren}</div>
    </Element>
  )

  if (!backgroundImage && !responsiveBackgroundImage) {
    return bodyComponent
  }

  const imageStyle = {
    position: "absolute",
    "--background-top-positioning-mobile": responsiveBackgroundImage
      ? "30%"
      : "0",
  }

  const element = renderAs
  const props = { className: s.wrapper }
  const children = [
    !responsiveBackgroundImage ? (
      <Img
        key="wrapper-image"
        src={backgroundImage}
        options={{
          maxWidth: 1440,
        }}
        className={s.background}
        style={{ position: "absolute" }}
        fluid
      />
    ) : (
      <ImageSelector
        {...responsiveBackgroundImage}
        key="wrapper-image"
        className={s.background}
        style={imageStyle}
      />
    ),
    bodyComponent,
  ]

  return React.createElement(element, props, children)
}
