import React from "react"
import withSbEditable from "components/_bloks/withSbEditable"
import { isValidArray, getPaddingProps } from "helpers"
import useDefaultPaddings from "hooks/useDefaultPaddings"
import TeamMembersColumn from "./TeamMembersColumn"
import { COLUMN_WIDTHS } from "./consts"
import s from "./teamMembersRow.module.scss"

const TeamMembersRow = ({
  columns,
  column_width,
  padding_top,
  padding_top_mb,
  padding_bottom,
  padding_bottom_mb,
}) => {
  const paddings = useDefaultPaddings({
    paddingTop: padding_top,
    paddingTopMb: padding_top_mb,
    paddingBottom: padding_bottom,
    paddingBottomMb: padding_bottom_mb,
  })

  if (!isValidArray(columns)) {
    return null
  }

  return (
    <ul
      className={s.container}
      style={{
        ...getPaddingProps(paddings, {
          variableNamePrefix: "team-members-row-padding",
        }),
      }}
    >
      {columns.map(team_members_column => (
        <TeamMembersColumn
          key={team_members_column._uid}
          blok={team_members_column}
          isWider={column_width === COLUMN_WIDTHS.THIRD}
        />
      ))}
    </ul>
  )
}

export default withSbEditable(TeamMembersRow)
