import React from "react"
import SbEditable from "storyblok-react"
import { getPaddingProps, isValidArray } from "helpers"
import CtaButton from "components/_common/CtaButton"
import { getCtaButtonProps } from "components/_common/CtaButton/helpers"
import withSbEditable from "components/_bloks/withSbEditable"
import useDefaultPaddings from "hooks/useDefaultPaddings"
import s from "./containerColumnButtons.module.scss"

const ContainerColumnButtons = ({
  buttons,
  padding_top,
  padding_top_mb,
  padding_bottom,
  padding_bottom_mb,
}) => {
  const paddings = useDefaultPaddings({
    paddingTop: padding_top,
    paddingTopMb: padding_top_mb,
    paddingBottom: padding_bottom,
    paddingBottomMb: padding_bottom_mb,
  })

  if (!isValidArray(buttons)) {
    return null
  }

  return (
    <div
      className={s.container}
      style={{
        ...getPaddingProps(paddings, {
          variableNamePrefix: "cta-buttons-padding",
        }),
      }}
    >
      {buttons.map(item => {
        return item.url_v2?.cached_url ? (
          <SbEditable key={item._uid} content={item}>
            <CtaButton {...getCtaButtonProps(item)} />
          </SbEditable>
        ) : null
      })}
    </div>
  )
}

export default withSbEditable(ContainerColumnButtons)
