import React from "react"
import Link from "components/_common/Link"
import s from "./jobListings.module.scss"

export default function JobListingsDepartmentItem({
  url,
  name,
  recruitingCategory,
  schedule,
  officeName,
}) {
  return (
    <li className={s.jobListingsDepartmentItem}>
      <div className={s.jobListingsDepartmentItemContainer}>
        <Link className={s.jobListingsDepartmentItemLink} to={url} newTab>
          {name}
        </Link>
        <span className={s.jobListingsDepartmentItemCategory}>
          {recruitingCategory}, {schedule}
        </span>
        <span className={s.jobListingsDepartmentItemOffice}>{officeName}</span>
      </div>
    </li>
  )
}
