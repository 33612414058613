import React from "react"
import Wrapper from "components/_common/Wrapper"
import withSbEditable from "components/_bloks/withSbEditable"
import isValidArray from "helpers/isValidArray"
import ContainerColumn from "./ContainerColumn"
import s from "./container.module.scss"

const Container = ({
  padding_top,
  padding_top_mb,
  padding_bottom,
  padding_bottom_mb,
  background_color,
  columns,
}) => {
  if (!isValidArray(columns) || columns.length !== 2) {
    return null
  }

  return (
    <Wrapper
      paddingTop={padding_top}
      paddingTopMb={padding_top_mb}
      paddingBottom={padding_bottom}
      paddingBottomMb={padding_bottom_mb}
      className={s.container}
      style={{
        gridTemplateColumns: `${columns[0].width}fr ${columns[1].width}fr`,
        backgroundColor: background_color,
      }}
    >
      {columns.map(container_column => (
        <ContainerColumn key={container_column._uid} blok={container_column} />
      ))}
    </Wrapper>
  )
}

export default withSbEditable(Container)
