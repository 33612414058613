import React from "react"
import Markdown from "components/_common/Markdown"
import withSbEditable from "components/_bloks/withSbEditable"
import s from "./sellerFeatures.module.scss"

const SellerFeaturesColumn = ({ heading, content }) => {
  return (
    <div className={s.columnItem}>
      <h3 className={s.heading}>{heading}</h3>
      {content && (
        <div className={s.markdownWrapper}>
          <Markdown>{content}</Markdown>
        </div>
      )}
    </div>
  )
}

export default withSbEditable(SellerFeaturesColumn)
