import React from "react"
import SectionHeading from "components/_common/SectionHeading"
import Wrapper from "components/_common/Wrapper"
import withSbEditable from "components/_bloks/withSbEditable"
import isValidArray from "helpers/isValidArray"
import StepsItem from "./StepsItem"
import s from "./steps.module.scss"

const Steps = ({
  heading,
  steps,
  padding_top,
  padding_top_mb,
  padding_bottom,
  padding_bottom_mb,
}) => {
  if (!isValidArray(steps)) {
    return null
  }

  return (
    <Wrapper
      paddingTop={padding_top}
      paddingTopMb={padding_top_mb}
      paddingBottom={padding_bottom}
      paddingBottomMb={padding_bottom_mb}
      className={s.bgSection}
    >
      <SectionHeading className={s.stepsHeading} text={heading} />
      <div className={s.grid}>
        {steps.map((steps_item, stepIndex) => (
          <StepsItem
            key={steps_item._uid}
            blok={steps_item}
            isLastItem={stepIndex === steps.length - 1}
          />
        ))}
      </div>
    </Wrapper>
  )
}

export default withSbEditable(Steps)
