// Props translation function
export function getCtaButtonProps(blok) {
  const {
    text,
    url_v2,
    color_variant: variant,
    color,
    cta_type_v2: type,
    asset,
    click_gtm_event,
    main_color: mainColor
  } = blok

  const link = url_v2?.cached_url

  return {
    text,
    link,
    variant,
    color,
    type,
    asset,
    click_gtm_event,
    mainColor
  }
}
