import isValidNumber from "helpers/isValidNumber"

// TODO: Incorporate side paddings into common Wrapper component and update all bloks to support it
export default function getSidePaddingProps({
  paddingLeft,
  paddingRight,
  paddingLeftMb,
  paddingRightMb,
}) {
  return {
    [`--padding-left`]: isValidNumber(paddingLeft) ? `${paddingLeft}px` : null,
    [`--padding-left-mobile`]: isValidNumber(paddingLeftMb)
      ? `${paddingLeftMb}px`
      : null,
    [`--padding-right`]: isValidNumber(paddingRight)
      ? `${paddingRight}px`
      : null,
    [`--padding-right-mobile`]: isValidNumber(paddingRightMb)
      ? `${paddingRightMb}px`
      : null,
  }
}
