import React from "react"

import BlogHeader from "components/_pages/blog-post/BlogHeader"
import BlogContent from "components/_pages/blog-post/BlogContent"
import BlogFooter from "components/_pages/blog-post/BlogFooter"
import EditorBlogPosts from "components/_pages/blog/BlogPosts/Editor"
import renderBloks from "helpers/renderBloks"
import { CONTENT_TYPES } from "consts"

function renderBlogPost(editorBlok) {
  return (
    <>
      <BlogHeader blok={editorBlok} />
      <BlogContent content={editorBlok.body} />
      <BlogFooter tags={editorBlok.tags} />
      {/* TODO: Adjust for editor */}
      {/* <BlogRelatedPosts posts={editorBlok.related_posts} /> */}
    </>
  )
}

function renderBlog(editorBlok) {
  return (
    <>
      {renderBloks(editorBlok.pre_body)}
      <EditorBlogPosts count={editorBlok.posts_per_page} />
      {renderBloks(editorBlok.post_body)}
    </>
  )
}

export default function renderEditorBloks(editorBlok) {
  const isPageOrProduct =
    editorBlok.component === CONTENT_TYPES.PAGE ||
    editorBlok.component === CONTENT_TYPES.PRODUCT
  const bloks = isPageOrProduct && editorBlok.body
  const pageOrProductComponents = isPageOrProduct && renderBloks(bloks)

  const blogPostComponents =
    editorBlok.component === CONTENT_TYPES.BLOG_POST &&
    renderBlogPost(editorBlok)

  const blogComponents =
    editorBlok.component === CONTENT_TYPES.BLOG && renderBlog(editorBlok)

  return pageOrProductComponents || blogPostComponents || blogComponents
}
