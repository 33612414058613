import { PAGINATION_PATH_REGEX, STORAGE_KEY_PREFIX } from "./consts"

export function getBannerStorageKey(path, isPaginating) {
  if (isPaginating) {
    if (PAGINATION_PATH_REGEX.test(path)) {
      const match = path.match(PAGINATION_PATH_REGEX)
      const basePath = match[1]
      return `${STORAGE_KEY_PREFIX}${basePath}`
    }
    throw new Error("Unexpected banner state during pagination")
  }
  return `${STORAGE_KEY_PREFIX}${path}`
}
