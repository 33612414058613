import React from "react"
import withSbEditable from "components/_bloks/withSbEditable"
import CtaButton from "components/_common/CtaButton"
import s from "./choosePlan.module.scss"

const ChoosePlanColumn = ({
  name,
  price_yearly,
  price_monthly,
  yearly_discount_cta,
  cta_name,
  cta_url,
  cta_type,
  additionalProps,
}) => {
  const { yearly } = additionalProps

  return (
    <div className={s.planWrapper}>
      <span className={s.planName}>{name}</span>
      <span className={s.planPrice}>
        {yearly ? price_yearly : price_monthly}
      </span>
      <span className={s.yearlyDiscountCta}>{yearly_discount_cta}</span>
      <div className={s.getAnOffer}>
        <CtaButton text={cta_name} link={cta_url?.cached_url} type={cta_type} />
      </div>
    </div>
  )
}

export default withSbEditable(ChoosePlanColumn)
