import React from "react"
import Link from "components/_common/Link"
import Img from "components/_common/Image"
import withSbEditable from "components/_bloks/withSbEditable"
import s from "./partners.module.scss"

const PartnersLogo = ({ asset, alt, link }) => {
  const isValidLink = link && link.cached_url

  const content = (
    <div className={s.logoWrapper}>
      <div className={s.logoInner}>
        <Img
          src={asset}
          options={{
            width: 100,
          }}
          alt={alt}
          fixed
        />
      </div>
    </div>
  )

  return (
    <>
      {isValidLink && (
        <Link
          to={link.cached_url}
          className={s.logo}
          aria-label={alt || "logo"}
          target="_blank"
        >
          {content}
        </Link>
      )}
      {!isValidLink && <div className={s.logo}>{content}</div>}
    </>
  )
}

export default withSbEditable(PartnersLogo)
