import React from "react"
import withSbEditable from "components/_bloks/withSbEditable"
import s from "./statistics.module.scss"

const StatisticsColumn = ({ value, description }) => {
  return (
    <div className={s.column}>
      <h3>{value}</h3>
      <p>{description}</p>
    </div>
  )
}

export default withSbEditable(StatisticsColumn)
