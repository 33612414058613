import { graphql, useStaticQuery } from "gatsby"

export default function useSiteMetadata() {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            title
            author
            description
            socials {
              twitter
            }
            integrations {
              personio
            }
            businessContact {
              locality
              region
              postalCode
              streetAddress
            }
          }
        }
      }
    `
  )
  return site.siteMetadata
}
