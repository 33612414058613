import React from "react"
import isValidArray from "helpers/isValidArray"
import loadable from "@loadable/component"
import Wrapper from "components/_common/Wrapper"
import withSbEditable from "components/_bloks/withSbEditable"
import s from "./socialTestimonials.module.scss"
import selectColorVariantClassName from "helpers/selectColorVariantClassName"

const LazySwiper = loadable(() => import("./Swiper"))

const SocialTestimonials = ({
  heading,
  caption,
  testimonials,
  padding_top,
  padding_top_mb,
  padding_bottom,
  padding_bottom_mb,
  variant,
}) => {
  if (!isValidArray(testimonials)) {
    return null
  }

  const selectClassName = selectColorVariantClassName(variant)

  const containerClassName = selectClassName(s.container, s.containerLight)

  const wrapperClassName = selectClassName(s.wrapper, s.wrapperLight)

  const testimonialsClassName = selectClassName(
    s.testimonials,
    s.testimonialsLight
  )

  return (
    <Wrapper
      fullWidth={true}
      paddingTop={padding_top}
      paddingTopMb={padding_top_mb}
      paddingBottom={padding_bottom}
      paddingBottomMb={padding_bottom_mb}
      style={{
        backgroundColor: "#0C2440",
      }}
    >
      <div className={containerClassName}>
        <Wrapper className={wrapperClassName}>
          <div className={s.content}>
            <div className={s.socialTestimonials}>
              <div className={s.headingContainer}>
                <div className={s.headingContent}>
                  <h2 className={s.heading}>{heading}</h2>
                  <div className={s.caption}>{caption}</div>
                </div>
              </div>
              <div className={testimonialsClassName}>
                <LazySwiper
                  variant={variant}
                  testimonials={testimonials}
                  fallback={<div style={{ height: "255px" }} />}
                />
              </div>
            </div>
          </div>
        </Wrapper>
      </div>
    </Wrapper>
  )
}

export default withSbEditable(SocialTestimonials)
