import React from "react"
import withSbEditable from "components/_bloks/withSbEditable"
import CtaButton from "components/_common/CtaButton"
import Renderer from "components/_common/Renderer"
import Img from "components/_common/Image"
import { IMAGE_SHAPES } from "./consts"
import { isValidImageWidth } from "./helpers"
import s from "./imageColumnItem.module.scss"
import Heading from "components/_common/Heading/heading"

const ImageColumnItem = ({
  image_reference,
  image_shape,
  image_width,
  image_width_maximized,
  image_title: title,
  image_alt: alt,
  heading,
  description,
  cta_text,
  cta_link_v2,
  cta_type_v2,
  additionalProps,
  heading_line,
  heading_line_position,
  background_color
}) => {
  const { onImageClick } = additionalProps

  const imageWidth = isValidImageWidth(image_width) ? image_width : 100

  return (
    <div className={s.container} style={{background: background_color}}>
      {image_reference && (
        <div className={s.imageWrapper} style={{ width: `${imageWidth}%` }}>
          <button
            aria-label="maximize"
            className={s.imageButton}
            onClick={() =>
              onImageClick({
                src: image_reference,
                width: image_width_maximized,
              })
            }
          >
            <Img
              src={image_reference}
              style={{
                borderRadius: image_shape === IMAGE_SHAPES.ROUND && "100%",
              }}
              className={s.image}
              alt={alt}
              title={title}
              fluid
            />
          </button>
        </div>
      )}
      {(heading || description) && (
        <div className={s.content}>
          {heading && (
            <Heading
              level="h4"
              headingLine={heading_line}
              linePosition={heading_line_position}
              className={s.heading}
            >
              {heading}
            </Heading>
          )}
          {description && (
            <Renderer content={description} className={s.description} />
          )}
        </div>
      )}
      {cta_text && cta_link_v2?.cached_url ? (
        <div className={s.cta}>
          <CtaButton
            text={cta_text}
            link={cta_link_v2.cached_url}
            type={cta_type_v2}
          />
        </div>
      ) : null}
    </div>
  )
}

export default withSbEditable(ImageColumnItem)
