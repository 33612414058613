import React from "react"
import cns from "classnames"
import { POSITION } from "consts"
import s from "./heading.module.scss"

export default function Heading({
  className,
  headingLine,
  linePosition,
  children,
  level = "h3",
}) {
  const CustomHeading = level

  return (
    <CustomHeading
      className={cns(className, s.headingText, {
        [s.lineLeft]: headingLine && linePosition === POSITION.LEFT,
        [s.lineRight]: headingLine && linePosition === POSITION.RIGHT,
      })}
    >
      {children}
    </CustomHeading>
  )
}
