import React from "react"
import Wrapper from "components/_common/Wrapper"
import withSbEditable from "components/_bloks/withSbEditable"
import isValidArray from "helpers/isValidArray"
import FooterColumn from "./FooterColumn"
import Link from "components/_common/Link"
import s from "./footer.module.scss"

const Footer = ({
  logo,
  footer_logo,
  columns,
  bottom_links,
  copyright_notice,
}) => {
  if (!isValidArray(columns)) {
    return null
  }

  return (
    <footer>
      <Wrapper className={s.footerWrapper} withoutDefaultPaddings>
        <img src={footer_logo} className={s.footerLogo} alt="Logo" fixed />
        <div className={s.footerColumns}>
          {columns.map(footer_column => (
            <FooterColumn key={footer_column._uid} blok={footer_column} />
          ))}
        </div>
        <span className={s.footerCopyrightNotice}>{copyright_notice}</span>
      </Wrapper>
    </footer>
  )
}

export default withSbEditable(Footer)
