import loadable from "@loadable/component"
import React from "react"
import withSbEditable from "components/_bloks/withSbEditable"
import SectionHeading from "components/_common/SectionHeading"
import Wrapper from "components/_common/Wrapper"
import isValidArray from "helpers/isValidArray"
import s from "./logoShowcase.module.scss"

const LazySwiper = loadable(() => import("./Swiper"))

const LogoShowcase = ({
  background_color,
  heading,
  logos,
  padding_top,
  padding_top_mb,
  padding_bottom,
  padding_bottom_mb,
}) => {
  if (!isValidArray(logos)) {
    return null
  }

  return (
    <Wrapper
      paddingTop={padding_top}
      paddingTopMb={padding_top_mb}
      paddingBottom={padding_bottom}
      paddingBottomMb={padding_bottom_mb}
      className={s.section}
      style={{
        backgroundColor: background_color,
      }}
      fullWidth
    >
      <SectionHeading className={s.heading} text={heading} />
      <div className={s.logos}>
        <LazySwiper
          logos={logos}
          fallback={<div style={{ height: "76px" }} />}
        />
      </div>
    </Wrapper>
  )
}

export default withSbEditable(LogoShowcase)
