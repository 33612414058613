import React from "react"
import SbEditable from "storyblok-react"
import CtaButton from "components/_common/CtaButton"
import { getCtaButtonProps } from "components/_common/CtaButton/helpers"
import withSbEditable from "components/_bloks/withSbEditable"
import useDefaultPaddings from "hooks/useDefaultPaddings"
import { isValidArray, getPaddingProps } from "helpers"
import s from "./ctaButtonGroup.module.scss"

const CtaButtonGroup = ({
  buttons,
  alignment,
  padding_top,
  padding_top_mb,
  padding_bottom,
  padding_bottom_mb,
}) => {
  const paddings = useDefaultPaddings({
    paddingTop: padding_top,
    paddingTopMb: padding_top_mb,
    paddingBottom: padding_bottom,
    paddingBottomMb: padding_bottom_mb,
  })

  if (!isValidArray(buttons)) {
    return null
  }

  return (
    <div
      className={s.ctaButtonGroup}
      style={{
        justifyContent: alignment || "flex-start",
        ...getPaddingProps(paddings),
      }}
    >
      {buttons.map(cta_button => (
        <SbEditable content={cta_button} key={cta_button._uid}>
          <CtaButton
            {...getCtaButtonProps(cta_button)}
            className={s.ctaButton}
          />
        </SbEditable>
      ))}
    </div>
  )
}

export default withSbEditable(CtaButtonGroup)
