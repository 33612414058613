import React, { useState } from "react"
import cns from "classnames"
import isValidArray from "helpers/isValidArray"
import withSbEditable from "components/_bloks/withSbEditable"
import Wrapper from "components/_common/Wrapper"
import Backdrop from "components/_common/Backdrop"
import SectionHeading from "components/_common/SectionHeading"
import Img from "components/_common/Image"
import { TEXT_VARIANTS } from "consts"
import { ITEM_WIDTHS } from "./consts"
import ImageColumnItem from "./ImageColumnItem"
import s from "./imageColumn.module.scss"

const ImageColumn = ({
  background_image,
  heading_text,
  heading_text_variant,
  item_width,
  items,
  padding_top,
  padding_top_mb,
  padding_bottom,
  padding_bottom_mb,
  background_color
}) => {
  const [backdropImage, setBackdropImage] = useState(null)

  function showBackdrop(image) {
    setBackdropImage(image)
  }

  function hideBackdrop() {
    setBackdropImage(null)
  }

  return (
    <>
      <Wrapper
        paddingTop={padding_top}
        paddingTopMb={padding_top_mb}
        paddingBottom={padding_bottom}
        paddingBottomMb={padding_bottom_mb}
        backgroundImage={background_image}
        style={{ "background-color": background_color }}
      >
        <SectionHeadingComponent
          headingText={heading_text}
          headingTextVariant={heading_text_variant}
        />
        <ItemsComponent
          items={items}
          itemWidth={item_width}
          onImageClick={showBackdrop}
        />
      </Wrapper>
      <BackdropComponent
        backdropImage={backdropImage}
        onBackdropClose={hideBackdrop}
      />
    </>
  )
}

function SectionHeadingComponent({ headingText, headingTextVariant }) {
  if (!headingText) {
    return null
  }

  return (
    <SectionHeading
      text={headingText}
      variant={headingTextVariant}
      className={cns(s.heading, {
        [s.headingWhite]: headingTextVariant === TEXT_VARIANTS.WHITE,
        [s.headingBlack]: headingTextVariant === TEXT_VARIANTS.BLACK,
      })}
    />
  )
}

function ItemsComponent({ items, itemWidth, onImageClick }) {
  if (!isValidArray(items)) {
    return null
  }

  return (
    <div
      className={cns(
        s.wrapper,
        itemWidth === ITEM_WIDTHS.THIRD && s.thirdsWrapper
      )}
    >
      {items.map(image_column_v1_item => (
        <ImageColumnItem
          key={image_column_v1_item._uid}
          blok={image_column_v1_item}
          onImageClick={onImageClick}
        />
      ))}
    </div>
  )
}

function BackdropComponent({ backdropImage, onBackdropClose }) {
  return (
    <Backdrop
      open={!!backdropImage}
      onBackdropClose={onBackdropClose}
      withCloseButton
    >
      {backdropImage && (
        <div
          style={{
            width: backdropImage.width && `${backdropImage.width}px`,
          }}
          className={s.backdropImageWrapper}
          onClick={e => e.stopPropagation()}
        >
          <Img src={backdropImage.src} fluid />
        </div>
      )}
    </Backdrop>
  )
}

export default withSbEditable(ImageColumn)
