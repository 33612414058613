import React from "react"
import cns from "classnames"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"
import s from "./backCta.module.scss"

export default function BackCta({ to, text, className }) {
  function handleBackClick() {
    window.history.back()
  }

  return (
    <div className={cns(s.backCtaWrapper, className && className)}>
      <button className={s.backCta} onClick={handleBackClick}>
        <ArrowBackIosIcon variant="contained" color="primary" />
        <span>{text}</span>
      </button>
    </div>
  )
}
