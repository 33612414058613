import React from "react"
import withSbEditable from "components/_bloks/withSbEditable"
import s from "./productSteps.module.scss"

const ProductStepsItem = ({ title, additionalProps }) => {
  const { index } = additionalProps

  return (
    <li className={s.stepBox}>
      <span className={s.stepNumber}>{index + 1}</span>
      <p className={s.stepText}>{title}</p>
    </li>
  )
}

export default withSbEditable(ProductStepsItem)
