import useSessionStorage from "hooks/useSessionStorage"
import {
  DEFAULT_STORAGE_VALUE,
  FALLBACK_STORAGE_VALUE,
  STORAGE_VALUES,
} from "./consts"
import { getBannerStorageKey } from "./helpers"

export default function useBanner(banner, path, isPaginating) {
  const skip =
    !banner ||
    !banner.enabled ||
    (isPaginating && !banner.visible_during_pagination)
  const bannerStorageKey = getBannerStorageKey(path, isPaginating)
  const [value, set] = useSessionStorage(
    bannerStorageKey,
    DEFAULT_STORAGE_VALUE,
    FALLBACK_STORAGE_VALUE,
    skip
  )

  const sessionOpened = value === STORAGE_VALUES.OPENED
  const isVisible = !skip && sessionOpened

  function close() {
    set(STORAGE_VALUES.CLOSED)
  }

  return [isVisible, close]
}
