import React from "react"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import Renderer from "components/_common/Renderer"
import withSbEditable from "components/_bloks/withSbEditable"
import s from "./expansionPanels.module.scss"

const ExpansionPanelsItem = ({ summary, details }) => {
  return (
    <ExpansionPanel
      classes={{ root: s.root, expanded: s.expanded }}
      elevation={0}
    >
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        {summary}
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Renderer content={details} className={s.details} />
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

export default withSbEditable(ExpansionPanelsItem)
