import React from "react"
import isValidArray from "helpers/isValidArray"
import Columns from "./components/Columns"

export default function Body({ rows, style }) {
  if (!isValidArray(rows)) {
    return null
  }

  return (
    <tbody style={style}>
      {rows.map(({ _uid, body }) => (
        <tr key={_uid}>
          <Columns columns={body} />
        </tr>
      ))}
    </tbody>
  )
}
