import React from "react"
import {
  MARK_BOLD,
  MARK_LINK,
  MARK_STYLED,
  NODE_BR,
  NODE_HR,
  NODE_IMAGE,
  NODE_PARAGRAPH,
} from "storyblok-rich-text-react-renderer"
import Newsletter from "components/_bloks/Newsletter"
import Link from "components/_common/Link"
import Table from "components/_bloks/Table"
import Text from "components/_bloks/Text"
import Image from "components/_bloks/Image"
import BlogCta from "components/_bloks/BlogCta"
import CtaButtonGroup from "components/_bloks/CtaButtonGroup"
import ImageGroup from "components/_bloks/ImageGroup"
import { Image as FixedImage } from "./nodes"
import s from "./renderer.module.scss"

export const RESOLVERS = {
  markResolvers: {
    [MARK_STYLED]: (children, props) => (
      <span className={s[props.class]}>{children}</span>
    ),
    [MARK_BOLD]: children => <strong>{children}</strong>,
    [MARK_LINK]: (children, props) => {
      const { href, target, linktype } = props

      if (linktype === "email" || href.startsWith("mailto:")) {
        const emailHref = linktype === "email" ? `mailto:${href}` : href
        return (
          <a href={emailHref} target={target}>
            {children}
          </a>
        )
      }
      return (
        <Link to={href} newTab={target === "_blank"}>
          {children}
        </Link>
      )
    },
  },
  nodeResolvers: {
    [NODE_IMAGE]: (_, props) => <FixedImage {...props} />,
    [NODE_BR]: () => <br />,
    [NODE_HR]: () => <hr className={s.hr} />,
    [NODE_PARAGRAPH]: children => {
      if (!children) {
        return <br />
      }
      return <p>{children}</p>
    },
  },
  blokResolvers: {
    blog_cta: blok => <BlogCta blok={blok} />,
    newsletter: blok => <Newsletter blok={blok} />,
    table: blok => <Table blok={blok} />,
    text: blok => <Text blok={blok} />,
    image: blok => <Image blok={blok} />,
    image_group: blok => <ImageGroup blok={blok} />,
    cta_button_group: blok => <CtaButtonGroup blok={blok} />,
  },
}
