import React from "react"
import JobListingsDepartmentItem from "./JobListingsDepartmentItem"
import { getPersonioJobListingUrl } from "./helpers"
import s from "./jobListings.module.scss"

export default function JobListingsDepartment({
  departmentName,
  jobListings,
  jobListingBaseUrl,
}) {
  return (
    <li className={s.jobListingsDepartment}>
      <h4 className={s.jobListingsDepartmentText}>{departmentName}</h4>
      <ul className={s.jobListingsDepartmentItems}>
        {jobListings.map(({ positionId, office, ...jobListingsItem }) => (
          <JobListingsDepartmentItem
            key={positionId}
            {...jobListingsItem}
            url={getPersonioJobListingUrl(jobListingBaseUrl, positionId)}
            officeName={office.name}
          />
        ))}
      </ul>
    </li>
  )
}
