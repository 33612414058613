import React from "react"
import SbEditable from "storyblok-react"
import isValidArray from "helpers/isValidArray"

export default function Head({ columns }) {
  if (!isValidArray(columns)) {
    return null
  }

  return (
    <thead>
      <tr>
        {columns.map(item => {
          const { _uid, value } = item

          return (
            <SbEditable key={_uid} content={item}>
              <th>{value}</th>
            </SbEditable>
          )
        })}
      </tr>
    </thead>
  )
}
