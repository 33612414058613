import React from "react"
import isValidArray from "helpers/isValidArray"
import withSbEditable from "components/_bloks/withSbEditable"
import CtaButton from "components/_common/CtaButton"
import Wrapper from "components/_common/Wrapper"
import SectionHeading from "components/_common/SectionHeading"
import IndicesColumn from "./IndicesColumn"
import s from "./indices.module.scss"

const Indices = ({
  heading,
  elements,
  cta_name,
  cta_url,
  cta_type,
  padding_top,
  padding_top_mb,
  padding_bottom,
  padding_bottom_mb,
}) => {
  if (!isValidArray(elements)) {
    return null
  }

  return (
    <Wrapper
      paddingTop={padding_top}
      paddingTopMb={padding_top_mb}
      paddingBottom={padding_bottom}
      paddingBottomMb={padding_bottom_mb}
    >
      <SectionHeading text={heading} />
      <div className={s.wrapper}>
        {elements.map(indices_column => (
          <IndicesColumn key={indices_column._uid} blok={indices_column} />
        ))}
      </div>
      <div className={s.cta}>
        <CtaButton text={cta_name} link={cta_url?.cached_url} type={cta_type} />
      </div>
    </Wrapper>
  )
}

export default withSbEditable(Indices)
