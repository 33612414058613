import React from "react"
import loadable from "@loadable/component"
import withSbEditable from "components/_bloks/withSbEditable"
import Wrapper from "components/_common/Wrapper"
import isValidArray from "helpers/isValidArray"
import ImageQuotesItem from "./ImageQuotesItem"

const LazySwiper = loadable(() => import("./Swiper"))

const ImageQuotes = ({
  items,
  slider_enabled,
  slider_mode,
  background_image,
  padding_top,
  padding_top_mb,
  padding_bottom,
  padding_bottom_mb,
}) => {
  if (!isValidArray(items)) {
    return null
  }

  return (
    <Wrapper
      backgroundImage={background_image?.filename}
      paddingTop={padding_top}
      paddingTopMb={padding_top_mb}
      paddingBottom={padding_bottom}
      paddingBottomMb={padding_bottom_mb}
      fullWidth={slider_enabled}
    >
      {slider_enabled ? (
        <LazySwiper
          items={items}
          mode={slider_mode}
          // TODO: Add fallback to avoid CLS issues
          // fallback={}
        />
      ) : (
        items.map(image_quotes_v1_item => (
          <ImageQuotesItem
            key={image_quotes_v1_item._uid}
            blok={image_quotes_v1_item}
          />
        ))
      )}
    </Wrapper>
  )
}

export default withSbEditable(ImageQuotes)
