import React from "react"
import cns from "classnames"
import Wrapper from "components/_common/Wrapper"
import SectionHeading from "components/_common/SectionHeading"
import withSbEditable from "components/_bloks/withSbEditable"
import useSiteMetadata from "hooks/useSiteMetadata"
import usePersonioData from "./usePersonioData"
import JobListingsDepartment from "./JobListingsDepartment"
import { getPersonioJobListingBaseUrl } from "./helpers"
import s from "./jobListings.module.scss"

const JobListings = ({
  heading,
  no_job_listings_label,
  padding_top,
  padding_top_mb,
  padding_bottom,
  padding_bottom_mb,
}) => {
  const [jobListingsByDepartmentName, jobListingsEmpty] = usePersonioData()
  const { integrations } = useSiteMetadata()
  const { personio: personioAccountId } = integrations
  const jobListingBaseUrl = getPersonioJobListingBaseUrl(personioAccountId)

  return (
    <Wrapper
      paddingTop={padding_top}
      paddingTopMb={padding_top_mb}
      paddingBottom={padding_bottom}
      paddingBottomMb={padding_bottom_mb}
    >
      {heading && <SectionHeading text={heading} />}

      {!jobListingsEmpty && (
        <ul
          className={cns(s.jobListings, heading && s.jobListingsHeadingOffset)}
        >
          {Object.keys(jobListingsByDepartmentName).map(departmentName => {
            const properDepartmentName = departmentName || "Others"

            return (
              <JobListingsDepartment
                key={properDepartmentName}
                departmentName={properDepartmentName}
                jobListings={jobListingsByDepartmentName[departmentName]}
                jobListingBaseUrl={jobListingBaseUrl}
              />
            )
          })}
        </ul>
      )}

      {jobListingsEmpty && (
        <p className={cns(s.jobListingsEmptyText, s.jobListingsHeadingOffset)}>
          {no_job_listings_label}
        </p>
      )}
    </Wrapper>
  )
}

export default withSbEditable(JobListings)
