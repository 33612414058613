import React from "react"
import loadable from "@loadable/component"
import Wrapper from "components/_common/Wrapper"
import SectionHeading from "components/_common/SectionHeading"
import withSbEditable from "components/_bloks/withSbEditable"
import isValidArray from "helpers/isValidArray"
import s from "./priceCommittee.module.scss"

const LazySwiper = loadable(() => import("./Swiper"))

const PriceCommitee = ({
  heading,
  subheading,
  columns,
  padding_top,
  padding_top_mb,
  padding_bottom,
  padding_bottom_mb,
}) => {
  if (!isValidArray(columns)) {
    return null
  }

  return (
    <Wrapper
      paddingTop={padding_top}
      paddingTopMb={padding_top_mb}
      paddingBottom={padding_bottom}
      paddingBottomMb={padding_bottom_mb}
    >
      <SectionHeading text={heading} />
      <p className={s.subHeading}>{subheading}</p>
      <div className={s.content}>
        <LazySwiper
          columns={columns}
          fallback={<div style={{ height: "270px" }} />}
        />
      </div>
    </Wrapper>
  )
}

export default withSbEditable(PriceCommitee)
