import React from "react"
import cns from "classnames"
import { TEXT_VARIANTS } from "consts"
import s from "./sectionHeading.module.scss"

export default function SectionHeading({
  text,
  variant = TEXT_VARIANTS.BLACK,
  className,
}) {
  const headingStyles = cns({
    [s.heading]: true,
    [s[variant]]: true,
    [className]: className !== "" && typeof className === "string",
  })

  return <h3 className={headingStyles}>{text}</h3>
}
