import isValidNumber from "helpers/isValidNumber"

const DEFAULT_OPTIONS = { variableNamePrefix: "padding" }

export default function getPaddingProps(
  { paddingTop, paddingBottom, paddingTopMb, paddingBottomMb },
  { variableNamePrefix } = DEFAULT_OPTIONS
) {
  return {
    [`--${variableNamePrefix}-top`]: isValidNumber(paddingTop)
      ? `${paddingTop}px`
      : null,
    [`--${variableNamePrefix}-top-mobile`]: isValidNumber(paddingTopMb)
      ? `${paddingTopMb}px`
      : null,
    [`--${variableNamePrefix}-bottom`]: isValidNumber(paddingBottom)
      ? `${paddingBottom}px`
      : null,
    [`--${variableNamePrefix}-bottom-mobile`]: isValidNumber(paddingBottomMb)
      ? `${paddingBottomMb}px`
      : null,
  }
}
