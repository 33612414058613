import React from "react"
import EditorBlogPostLink from "./BlogPostLink/Editor"
import Wrapper from "components/_common/Wrapper"
import { DEFAULT_BLOG_POSTS_PER_PAGE } from "consts"
import s from "./blogPosts.module.scss"

// Component used as a placeholder inside Storyblok Live Editor
export default function EditorBlogPosts({ count }) {
  return (
    <Wrapper withoutDefaultPaddings>
      <div className={s.articlesList}>
        {Array.from(
          { length: count || DEFAULT_BLOG_POSTS_PER_PAGE },
          (_, editorBlogPostLinkIndex) => (
            <EditorBlogPostLink
              key={`editor-blog-post-link-${editorBlogPostLinkIndex}`}
            />
          )
        )}
      </div>
    </Wrapper>
  )
}
