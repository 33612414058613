import React from "react"
import { Helmet } from "react-helmet"
import { useIntl } from "gatsby-plugin-intl"
import PropTypes from "prop-types"
import { isChinese, isDefault, isStoryblokChinese } from "helpers/locales"
import useSiteMetadata from "hooks/useSiteMetadata"
import {
  CHINESE_ISO_639_1,
  COLORS,
  LANGUAGES,
  ROUTES,
  STORYBLOK_HOME_SLUG,
} from "consts"
import { cleanHref, isWebLink } from "helpers"

export default function SEO({ data, path: basePath, langLinks }) {
  const intl = useIntl()
  const currentLang = intl.locale

  const {
    author,
    businessContact,
    title: titlePrefix,
    description: defaultDescription,
    siteUrl: domain,
  } = useSiteMetadata()

  const {
    cover,
    title: titleSuffix,
    description: customDescription,
    noindex,
  } = data

  const metaTitle = `${titlePrefix} - ${titleSuffix}`

  const metaDescription = customDescription || defaultDescription

  const customImage = isWebLink(cover) ? cover : `https:${cover}`
  const metaImage = cover ? customImage : `${domain}/assets/logistics.jpg`

  const path =
    basePath === `/${STORYBLOK_HOME_SLUG}` ? ROUTES.HOME_PRESLASHLESS : basePath
  const metaUrl = `${domain}${cleanHref(path)}`
  const htmlLang = isStoryblokChinese(intl.locale)
    ? CHINESE_ISO_639_1
    : currentLang
  return (
    <Helmet>
      <html lang={htmlLang} />

      {noindex && <meta name="robots" content="noindex" />}

      <title>{metaTitle}</title>

      <meta name="author" content={author} />
      <meta name="description" content={metaDescription} />
      <meta name="theme-color" content={COLORS.MH_BLUE} />

      {/* Business contact tags */}
      <meta
        property="business:contact_data:street_address"
        content={businessContact.streetAddress}
      />
      <meta
        property="business:contact_data:locality"
        content={businessContact.locality}
      />
      <meta
        property="business:contact_data:region"
        content={businessContact.region}
      />
      <meta
        property="business:contact_data:postal_code"
        content={businessContact.postalCode}
      />

      {/* OpenGraph tags */}
      <meta property="og:title" content={metaTitle} />
      <meta property="og:url" content={metaUrl} />
      <meta property="og:type" content="business.business" />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:image" content={metaImage} />
      <meta property="og:image:width" content="1000" />
      <meta property="og:image:height" content="370" />

      {/* Twitter tags */}
      <meta property="twitter:title" content={metaTitle} />
      <meta property="twitter:description" content={metaDescription} />
      <meta name="twitter:image" content={metaImage} />
      <meta name="twitter:card" content="summary_large_image" />

      {langLinks?.map(lang => {
        const linkLang = isDefault(lang) ? "" : `/${lang}`

        const href = `${domain}${cleanHref(`${linkLang}${path}`)}`

        const hrefLang = isChinese(lang) ? CHINESE_ISO_639_1 : lang

        return (
          <link key={lang} rel="alternate" hrefLang={hrefLang} href={href} />
        )
      })}
      {!langLinks && (
        <link
          rel="alternate"
          hrefLang={LANGUAGES.DEFAULT}
          href={`${domain}${cleanHref(`${LANGUAGES.DEFAULT}${path}`)}`}
        />
      )}
      <link rel="alternate" hrefLang="x-default" href={metaUrl} />
    </Helmet>
  )
}

SEO.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    cover: PropTypes.string,
  }).isRequired,
  path: PropTypes.string.isRequired,
  langLinks: PropTypes.arrayOf(PropTypes.string),
}
