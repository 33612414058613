import React from "react"
import RichText from "components/_bloks/RichText"
import Table from "components/_bloks/Table"
import ContainerColumnButtons from "./ContainerColumnButtons"

export default {
  rich_text: props => <RichText {...props} column />,
  table: props => <Table {...props} column />,
  container_column_buttons: ContainerColumnButtons,
}
