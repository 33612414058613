import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import Link from "components/_common/Link"
import Img from "components/_common/Image"
import { isDefault } from "helpers/locales"
import { ROUTES } from "consts"
import s from "./logo.module.scss"

export default function Logo({ logo }) {
  const intl = useIntl()

  const logoLink = isDefault(intl.locale)
    ? ROUTES.HOME_PRESLASH
    : `/${intl.locale}/`

  return (
    logo && (
      <Link className={s.logoLink} to={logoLink}>
        <img src={logo} alt="Metalshub homepage" />
      </Link>
    )
  )
}
