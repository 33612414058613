import React from "react"
import isValidArray from "helpers/isValidArray"
import SectionHeading from "components/_common/SectionHeading"
import Wrapper from "components/_common/Wrapper"
import Img from "components/_common/Image"
import withSbEditable from "components/_bloks/withSbEditable"
import OfferFeature from "./OfferFeature"
import s from "./offer.module.scss"

const Offer = ({
  features,
  featured_image,
  heading,
  padding_top,
  padding_top_mb,
  padding_bottom,
  padding_bottom_mb,
}) => {
  if (!isValidArray(features)) {
    return null
  }

  return (
    <Wrapper
      paddingTop={padding_top}
      paddingTopMb={padding_top_mb}
      paddingBottom={padding_bottom}
      paddingBottomMb={padding_bottom_mb}
    >
      <SectionHeading text={heading} />
      <div className={s.content}>
        <div className={s.image}>
          <Img
            src={featured_image}
            options={{
              maxWidth: 530,
            }}
            fluid
          />
        </div>
        <div className={s.featuresWrapper}>
          <ul>
            {features.map(offer_feature => (
              <OfferFeature key={offer_feature._uid} blok={offer_feature} />
            ))}
          </ul>
        </div>
      </div>
    </Wrapper>
  )
}

export default withSbEditable(Offer)
