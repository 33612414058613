import React from "react"
import withSbEditable from "components/_bloks/withSbEditable"
import Link from "components/_common/Link"
import s from "./multipleItem.module.scss"

const NavigationItemDropdownLink = ({ url, text, type }) => (
  <Link to={url.cached_url} activeClassName={s.activeLink} type={type}>
    {text}
  </Link>
)

export default withSbEditable(NavigationItemDropdownLink)
