import React from "react"
import isValidArray from "helpers/isValidArray"
import Wrapper from "components/_common/Wrapper"
import withSbEditable from "components/_bloks/withSbEditable"
import ImageSelector from "../ImageSelector"
import s from "./slideshowHero.module.scss"
import Button from "components/_common/Button"
import loadable from "@loadable/component"

const LazySwiper = loadable(() => import("./Swiper"))

const SlideshowHero = blok => {
  const backgroundImage = blok?.image?.[0]
  const areSlidesValid = isValidArray(blok.slideshow_images)

  return (
    <div className={s.slideshowHeroContainer}>
      <Wrapper>
        <h1 className={s.slideshowHeroHeading}>{blok.heading}</h1>
        <h3 className={s.slideshowHeroText}>{blok.text}</h3>
      </Wrapper>
      {backgroundImage && (
        <ImageSelector
          {...blok.image[0]}
          className={s.slideshowHeroBackgroundImage}
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
            zIndex: "-1",
          }}
        />
      )}
      {areSlidesValid ? (
        <div className={s.slideshowHeroSlidesContainer}>
          <LazySwiper slides={blok.slideshow_images} />
        </div>
      ) : null}
      <div className={s.slideshowHeroCutout} />
      <div className={s.controlsContainer}>
        <div className={s.slideshowHeroSliderButtons}>
          <Button className="swiperSlideshowHeroButtonPrev" variant="icon" />
          <Button className="swiperSlideshowHeroButtonNext" variant="icon" />
        </div>
      </div>
    </div>
  )
}

export default withSbEditable(SlideshowHero)
