import React from "react"
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share"
import useSiteMetadata from "hooks/useSiteMetadata"
import getPathFromSlug from "helpers/getPathFromSlug"
import s from "./socialButtons.module.scss"

export default function SocialButtons({ title, excerpt, tags, slug }) {
  const { siteUrl: domain, socials } = useSiteMetadata()

  const areTagsValid = !!(typeof tags === "string" && tags)

  const facebookProps = {
    quote: excerpt || "",
    // field type was converted in CMS in the past, hence this check to prevent errors when its an array
    hashtag: areTagsValid ? tags : "#metalshub",
  }

  const twitterProps = {
    hashtags: ["metalshub"],
    via: socials.twitter,
    title,
  }

  const path = getPathFromSlug(slug)
  const url = `${domain}${path}/`

  const commonProps = {
    className: s.socialButton,
    url,
  }

  return (
    <div className={s.socialButtons}>
      <FacebookShareButton
        {...{
          ...commonProps,
          ...facebookProps,
        }}
      >
        <FacebookIcon size={25} round />
      </FacebookShareButton>
      <TwitterShareButton
        {...{
          ...commonProps,
          ...twitterProps,
        }}
      >
        <TwitterIcon size={25} round />
      </TwitterShareButton>
      <LinkedinShareButton
        {...{
          ...commonProps,
        }}
      >
        <LinkedinIcon size={25} round />
      </LinkedinShareButton>
    </div>
  )
}
