import React from "react"
import SectionHeading from "components/_common/SectionHeading"
import Wrapper from "components/_common/Wrapper"
import withSbEditable from "components/_bloks/withSbEditable"
import isValidArray from "helpers/isValidArray"
import ProductStepsItem from "./ProductStepsItem"
import s from "./productSteps.module.scss"

const ProductSteps = ({
  heading,
  steps,
  padding_top,
  padding_top_mb,
  padding_bottom,
  padding_bottom_mb,
}) => {
  if (!isValidArray(steps)) {
    return null
  }

  return (
    <Wrapper
      paddingTop={padding_top}
      paddingTopMb={padding_top_mb}
      paddingBottom={padding_bottom}
      paddingBottomMb={padding_bottom_mb}
    >
      <SectionHeading text={heading} />
      <ul className={s.stepsWrapper}>
        {steps.map((step, stepIndex) => (
          <ProductStepsItem key={step._uid} blok={step} index={stepIndex} />
        ))}
      </ul>
    </Wrapper>
  )
}

export default withSbEditable(ProductSteps)
