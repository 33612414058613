export function getImageProps(imageProps) {
  if (typeof imageProps !== "object") {
    return null
  }

  return {
    ...(imageProps.title && { title: imageProps.title }),
    ...(imageProps.alt && { alt: imageProps.alt }),
  }
}
