import React from "react"
import cns from "classnames"
import withSbEditable from "components/_bloks/withSbEditable"
import useDefaultPaddings from "hooks/useDefaultPaddings"
import { isValidArray, getPaddingProps } from "helpers"
import ImageGroupItem from "./ImageGroupItem"
import s from "./imageGroup.module.scss"

const ImageGroup = ({
  items,
  alignment,
  height,
  padding_top,
  padding_top_mb,
  padding_bottom,
  padding_bottom_mb,
}) => {
  const paddings = useDefaultPaddings({
    paddingTop: padding_top,
    paddingTopMb: padding_top_mb,
    paddingBottom: padding_bottom,
    paddingBottomMb: padding_bottom_mb,
  })

  const isOnlyImage = items.length === 1

  if (!isValidArray(items)) {
    return null
  }

  return (
    <div
      className={cns(s.imageGroup, !isOnlyImage && s.imagesWrapper)}
      style={{
        justifyContent: isOnlyImage && (alignment || "flex-start"),
        ...getPaddingProps(paddings),
      }}
    >
      {items.map(image_group_item => (
        <ImageGroupItem
          key={image_group_item._uid}
          blok={image_group_item}
          height={height}
          isOnlyImage={isOnlyImage}
        />
      ))}
    </div>
  )
}

export default withSbEditable(ImageGroup)
