import React from "react"
import Wrapper from "components/_common/Wrapper"
import withSbEditable from "components/_bloks/withSbEditable"
import isValidArray from "helpers/isValidArray"
import TeamMembersRow from "./TeamMembersRow"

const TeamMembers = ({
  rows,
  padding_top,
  padding_top_mb,
  padding_bottom,
  padding_bottom_mb,
  background_color,
}) => {
  if (!isValidArray(rows)) {
    return null
  }

  return (
    <Wrapper
      paddingTop={padding_top}
      paddingTopMb={padding_top_mb}
      paddingBottom={padding_bottom}
      paddingBottomMb={padding_bottom_mb}
      style={{ backgroundColor: background_color }}
    >
      {rows.map(team_members_row => (
        <TeamMembersRow key={team_members_row._uid} blok={team_members_row} />
      ))}
    </Wrapper>
  )
}

export default withSbEditable(TeamMembers)
