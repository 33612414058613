import React from "react"
import { navigate } from "gatsby"
import { changeLocale, injectIntl } from "gatsby-plugin-intl"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import {
  isDefault,
  isStoryblokChinese,
  isStoryblokDefault,
} from "helpers/locales"
import { LANGUAGES, ROUTES } from "consts"
import s from "./changeLanguage.module.scss"

import globeIcon from "images/globe_icon.svg"

// TODO: Refactor - entire component and simplify language logic
function prepareStoryblokLocale(lang) {
  if (isStoryblokDefault(lang)) {
    return LANGUAGES.DEFAULT
  }

  if (isStoryblokChinese(lang)) {
    return LANGUAGES.CHINESE
  }

  if (typeof lang === "string") {
    return lang
  }

  return false
}

function mainPath(path) {
  const langs = [`en`, `de`, `es`, `zh-hans`, `fr`, `it`, `pt`]

  let isMainPath = false

  langs.forEach(lang => {
    if (path === `/${lang}` || path === `/${lang}/`) {
      isMainPath = true
    }
  })

  return isMainPath
}

export class changeLanguage extends React.Component {
  state = {
    language:
      prepareStoryblokLocale(this.props.storyblokLocale) ||
      this.props.intl.locale,
    languageNameShorter: {
      en: "EN",
      es: "ES",
      de: "DE",
      "zh-hans": "CN",
      fr: "FR",
      pt: "PT",
    },
    languageName: {
      en: "English",
      es: "Spanish",
      de: "German",
      "zh-hans": "Chinese",
      fr: "French",
      pt: "Portuguese",
    },
    languageOriginal: {
      en: "English",
      es: "Español",
      de: "Deutsch",
      "zh-hans": "中文",
      fr: "Français",
      pt: "Português",
    },
    languages: [`en`, `de`, `es`, `zh-hans`, `fr`, `pt`],
  }

  handleChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })

    const pathName =
      typeof window !== "undefined" ? window.location.pathname : ""

    const storyblokPage = typeof this.props.storyblokLocale === "string"

    if (
      storyblokPage &&
      !isDefault(event.target.value) &&
      pathName !== ROUTES.HOME_PRESLASH
    ) {
      let langToReplace = ""
      this.state.languages.forEach(lang => {
        if (pathName.includes(`/${lang}/`)) {
          langToReplace = lang
        }
      })
      if (!this.props.langLinks.includes(event.target.value)) {
        navigate(`/${event.target.value}${ROUTES.HOME_PRESLASH}`)
      } else {
        if (langToReplace.length <= 0) {
          navigate(`/${event.target.value}${pathName}`)
        } else {
          navigate(
            pathName.replace(`${langToReplace}/`, `${event.target.value}/`)
          )
        }
      }

      return
    }

    if (
      storyblokPage &&
      isDefault(event.target.value) &&
      pathName !== ROUTES.HOME_PRESLASH
    ) {
      let langToReplace = ""
      this.state.languages.forEach(lang => {
        if (pathName.includes(`/${lang}/`)) {
          langToReplace = lang
        }
      })
      if (!this.props.langLinks.includes(LANGUAGES.DEFAULT)) {
        navigate(ROUTES.HOME_PRESLASH)
      } else {
        if (langToReplace.length <= 0) {
          navigate(pathName)
        } else {
          navigate(pathName.replace(`${langToReplace}/`, ""))
        }
      }
      return
    }

    if (
      storyblokPage &&
      !isDefault(event.target.value) &&
      isDefault(this.state.language)
    ) {
      if (!this.props.langLinks.includes(event.target.value)) {
        navigate(ROUTES.HOME_PRESLASH)
        return
      }
      navigate(`/${event.target.value}${pathName}`)
      return
    }

    if (storyblokPage && pathName === ROUTES.HOME_PRESLASH) {
      if (isDefault(event.target.value)) {
        navigate(ROUTES.HOME_PRESLASH)
      } else {
        navigate(`/${event.target.value}/`)
      }
      return
    }

    if (storyblokPage && mainPath(pathName) && !isDefault(event.target.value)) {
      navigate(`/${event.target.value}/`)
      return
    }

    if (storyblokPage && mainPath(pathName) && isDefault(event.target.value)) {
      navigate(ROUTES.HOME_PRESLASH)
      return
    }

    if (!this.props.storyblokLocale) {
      changeLocale(event.target.value)
    }
  }

  render() {
    return (
      <div className={s.wrapper}>
        <img src={globeIcon} className={s.globeIcon} alt="" />
        <Select
          onChange={this.handleChange}
          value={this.state.language}
          className={s.select}
          name="language"
          renderValue={value => `${this.state.languageNameShorter[value]}`}
          MenuProps={{
            disableScrollLock: true,
          }}
        >
          {this.state.languages.map((language, index) => {
            return (
              <MenuItem
                component="a"
                key={index}
                value={language}
                className={s.dropdownItem}
              >
                {this.props.intl.formatMessage({
                  id: this.state.languageName[language],
                })}{" "}
                | {this.state.languageOriginal[language]}
              </MenuItem>
            )
          })}
        </Select>
      </div>
    )
  }
}

export default injectIntl(changeLanguage)
