import React from "react"
import Img from "components/_common/Image"
import withSbEditable from "components/_bloks/withSbEditable"
import useDefaultPaddings from "hooks/useDefaultPaddings"
import getPaddingProps from "helpers/getPaddingProps"
import s from "./image.module.scss"

const Image = ({
  image,
  title,
  alt,
  width,
  height,
  alignment,
  padding_top,
  padding_top_mb,
  padding_bottom,
  padding_bottom_mb,
}) => {
  const paddings = useDefaultPaddings({
    paddingTop: padding_top,
    paddingTopMb: padding_top_mb,
    paddingBottom: padding_bottom,
    paddingBottomMb: padding_bottom_mb,
  })

  return (
    <div
      className={s.container}
      style={{
        justifyContent: alignment || "flex-start",
        ...getPaddingProps(paddings),
      }}
    >
      <div
        className={s.wrapper}
        style={{
          maxHeight: height && `${height}px`,
          maxWidth: width && `${width}px`,
        }}
      >
        <Img
          src={image}
          options={{
            maxWidth: width || null,
            maxHeight: height || null,
          }}
          alt={alt}
          title={title}
          fluid
        />
      </div>
    </div>
  )
}

export default withSbEditable(Image)
