import React from "react"
import cns from "classnames"
import Wrapper from "components/_common/Wrapper"
import Button from "components/_common/Button"
import Img from "components/_common/Image"
import withSbEditable from "components/_bloks/withSbEditable"
import s from "./productDownload.module.scss"

const ProductDownload = ({
  background_image,
  asset_cover,
  cta_type,
  link,
  asset,
  heading,
  description,
  cta_title,
  padding_top,
  padding_top_mb,
  padding_bottom,
  padding_bottom_mb,
}) => {
  const buttonLink =
    cta_type === "link" && link?.cached_url ? link.cached_url : asset.filename

  return (
    <Wrapper
      paddingTop={padding_top}
      paddingTopMb={padding_top_mb}
      paddingBottom={padding_bottom}
      paddingBottomMb={padding_bottom_mb}
      backgroundImage={background_image}
      className={s.wrapper}
      fullWidth
    >
      <div className={cns("container", s.container)}>
        <div>
          <h3 className={s.heading}>{heading}</h3>
          <p className={s.content}>{description}</p>
          <div className={s.downloadButton}>
            <Button link={buttonLink} text={cta_title} />
          </div>
        </div>
        <Img
          src={asset_cover}
          options={{
            maxWidth: 460,
          }}
          className={s.downloadImage}
          fluid
        />
      </div>
    </Wrapper>
  )
}

export default withSbEditable(ProductDownload)
