import React from "react"
import withSbEditable from "components/_bloks/withSbEditable"
import s from "./offer.module.scss"

import iconCheck from "images/icon_check.svg"

const OfferFeature = ({ name }) => {
  return (
    <li className={s.singleFeature}>
      <img src={iconCheck} aria-hidden="true" alt="" className={s.checkIcon} />
      {name}
    </li>
  )
}

export default withSbEditable(OfferFeature)
